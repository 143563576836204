import { useCallback, useEffect, useState } from 'react';
import DataGridPagination from '../../components/DataGridPagination';
import DataGridToolbar from '../../components/DataGridToolbar';
import {
    DataGrid,
} from '@mui/x-data-grid';
import { LinearProgress } from '@material-ui/core';
import Helper from '../../utils/Helper';
import { getStatistics } from '../../api/common-api';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../components/Loader';



const Statistics = () => {
    const [pageSize, setPageSize] = useState(5);
    const [rows, setRows] = useState([]);
    const [filteredRow, setFilteredRow] = useState([]);
    const [searchText, setSearchText] = useState('');
    
    const [loading, setLoading] = useState(true)
    
    const [date, setDate] = useState(moment().format('yyyy-MM-DD'))             // eslint-disable-line
    const [lastDay, setLastDay] = useState('')                                  // eslint-disable-line
    const [totalAdd, setTotalAdd] = useState(0)
    const [totalDownload, setTotalDownload] = useState(0)
    const [totalRemove, setTotalRemove] = useState(0)
    const [totalOtherAction, setTotalOtherAction] = useState(0)

    const pieOption = {
        chart: {
            type: 'pie',
        },
        colors: ['#00E396', '#008FFB', '#FF4560', '#FEB019'],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        sparkline: {
            enabled: true
        },
        labels: ['Add', 'Download', 'Delete', 'Other'],
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: true,
                    position: 'bottom'
                }
            }
        }]
    }

    const mixSeries = [{
        name: 'Add',
        type: 'column',
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
    }, {
        name: 'Download',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    }, {
        name: 'Remove',
        type: 'line',
        data: [5, 20, 40, 10, 3, 8, 20, 25, 32, 10, 19]
    }, {
        name: 'Other',
        type: 'line',
        data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
    }]

    const mixOption = {
        chart: {
            type: 'line',
            // stacked: false,
            toolbar: {
                show: false
            }
        },
        colors: ['#00E396', '#008FFB', '#FF4560', '#FEB019'],
        stroke: {
            width: [0, 2, 5],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '50%'
            }
        },
        legend: {
            position: 'top'
        },
        fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            },
        },
        labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
            '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'
        ],
        markers: {
            size: 2
        },
        xaxis: {
            type: 'datetime'
        },
        yaxis: {
            min: 0
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;

                }
            }
        }
    }


    const width = window.screen.width

    const fetchData = useCallback(async () => {
        setLoading(true)
        const res = await getStatistics(date, lastDay)
        console.log("Statistics :", res)

        setLoading(false)
        if (res.status === 200) {
            if(res.data.success){
                setRows(res.data.data.table)
                setFilteredRow(res.data.data.table)
                setTotalAdd(res.data.data.total_action_add)
                setTotalDownload(res.data.data.total_action_download)
                setTotalRemove(res.data.data.total_action_delete)
                
                const existData = [...res.data.data.table]
                setTotalOtherAction(existData.filter(item => item.activity !== 'add' && item.activity !== 'download' && item.activity !== 'delete' && item.activity !== 'remove').length)
            }
        } else {
            Helper.bulkResponseError(res)
        }
    }, [date, lastDay])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    const requestSearch = (searchValue) => {
        setSearchText(searchValue)

        const newData = [...rows]
        if (searchValue) {
            const filtered = newData.filter((item) => {
                return (
                    item?.activity?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.filename?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.type?.toLowerCase().includes(searchValue.toLowerCase())
                )
            });

            setFilteredRow(filtered)
        } else {
            setFilteredRow(rows)
        }
    };

    const COLUMNS = [
        {
            field: "id", headerName: 'ID', hide: true, maxWidth: 30, headerClassName: 'bg-gray-100',
        },
        {
            field: 'created_at', headerName: "Datetime",
            type: 'dateTime', minWidth: 200, headerClassName: 'bg-gray-100',
            // valueGetter: ({ value }) => value && new Date(value),
            valueGetter: ({ value }) => moment(value).format('DD-MM-yyyy HH:mm:ss')
        },
        {
            field: 'user', headerName: 'User',
            minWidth: 200, headerClassName: 'bg-gray-100',
            valueGetter: ({value}) => {
                return value[0]?.name
            }
        },
        {
            field: 'activity', headerName: 'Activity',
            width: 110, headerClassName: 'bg-gray-100',
        },
        {
            field: 'type', headerName: 'Type',
            width: 110, headerClassName: 'bg-gray-100',
        },
        {
            field: 'filename',
            headerName: 'File / Dir Name',
            description:
                'The identification used by the person with access to the online service.',
            flex: 1,
            minWidth: 250, headerClassName: 'bg-gray-100',
        },
    ]


    return (
        <>
            <div className="flex flex-col px-4 md:px-6 py-5">

                <div className='mb-5 flex items-center text-base md:text-xl'>
                    <i className="ri-pie-chart-2-fill text-lg md:text-2xl mr-2 md:mr-3"></i>
                    <h1 className='font-bold'>Statistics</h1>
                </div>

                <div className='flex item-center mb-5'>
                    <label className='flex justify-center items-center mr-4' htmlFor='date'>Select Date :</label>
                    <input type='date' className='outline-none border border-slate-600 focus:border-orange-500 focus:ring-1 focus:ring-orange-500 rounded-lg px-5 py-2 cursor-pointer' id='date' onChange={(e)=>setDate(e.target.value)} />
                </div>

                <div className='w-full flex flex-col md:flex-row space-x-0 lg:space-x-5 space-y-5 lg:space-y-0 mb-5'>
                    <div className='w-full lg:w-[26%] flex flex-col space-y-4 mb-2'>
                        <div className='w-full h-[6rem] bg-white rounded-md shadow-lg flex text-white'>
                            <div className='bg-green-600 w-[7rem] 2xl:w-[7.5rem] h-full rounded-tl-md rounded-bl-md'>
                                <div className='flex justify-center items-center h-full'>
                                    <i className='ri-upload-cloud-fill text-6xl'></i>
                                </div>
                            </div>
                            <div className='px-5 py-3 text-dark'>
                                <p>Add</p>
                                <p className='font-bold text-base mt-1'>{totalAdd}</p>
                            </div>
                        </div>
                        <div className='w-full h-[6rem] bg-white rounded-md shadow-lg flex text-white'>
                            <div className='bg-blue-500 w-[7rem] 2xl:w-[7.5rem] h-full rounded-tl-md rounded-bl-md'>
                                <div className='flex justify-center items-center h-full'>
                                    <i className='ri-download-cloud-fill text-6xl'></i>
                                </div>
                            </div>
                            <div className='px-5 py-3 text-dark'>
                                <p>Download</p>
                                <p className='font-bold text-base mt-1'>{totalDownload}</p>
                            </div>
                        </div>
                        <div className='w-full h-[6rem] bg-white rounded-md shadow-lg flex text-white'>
                            <div className='bg-red-600 w-[7rem] 2xl:w-[7.5rem] h-full rounded-tl-md rounded-bl-md'>
                                <div className='flex justify-center items-center h-full'>
                                    <i className='bi bi-trash-fill text-6xl'></i>
                                </div>
                            </div>
                            <div className='px-5 py-3 text-dark'>
                                <p>Remove</p>
                                <p className='font-bold text-base mt-1'>{totalRemove}</p>
                            </div>
                        </div>
                        <div className='w-full h-[6rem] bg-white rounded-md shadow-lg flex text-white'>
                            <div className='bg-yellow-600 w-[7rem] 2xl:w-[7.5rem] h-full rounded-tl-md rounded-bl-md'>
                                <div className='flex justify-center items-center h-full'>
                                    <i className='bi bi-play-circle-fill text-6xl'></i>
                                </div>
                            </div>
                            <div className='px-5 py-3 text-dark'>
                                <p>Other</p>
                                <p className='font-bold text-base mt-1'>{totalOtherAction}</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:w-[26%] h-max bg-white rounded-md shadow-lg'>
                        <p className='font-semibold px-5 pt-5 pb-2 border-b'>Main Activities</p>
                        <div className='w-full flex justify-center'>
                            {/* <ReactApexChart options={pieOption} series={[totalAdd, totalDownload, totalRemove, 0]} type="pie" width={width>1366? '160%':'135%'} /> */}
                            <ReactApexChart options={pieOption} series={[totalAdd, totalDownload, totalRemove, totalOtherAction]} type="pie" width={width>1366? '160%':'135%'} />
                        </div>
                    </div>
                    <div className='w-full lg:w-[44.5%] h-max bg-white rounded-md shadow-lg'>
                        <p className='font-semibold px-5 pt-5 pb-2 border-b'>Trendline</p>
                        <div className='w-full flex justify-center'>
                            <ReactApexChart options={mixOption} series={mixSeries} type="line" height={365} width={width>1366? '235%': width<800? '125%' : '158%'} />
                        </div>
                    </div>
                </div>

                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5 flex items-center md:text-base'>
                        <i className="bi bi-card-checklist text-base md:text-xl mr-2 md:mr-3"></i>
                        <h1 className='font-semibold'>Activity Log</h1>
                    </div>
                    <div>
                        <div>
                            <DataGrid
                                sx={{
                                    fontFamily: 'poppins'
                                }}

                                columns={COLUMNS}
                                rows={filteredRow}
                                components={{
                                    Toolbar: DataGridToolbar,
                                    Pagination: () => <DataGridPagination countData={rows.length} title='Data' />,
                                    LoadingOverlay: LinearProgress,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        searchText: searchText,
                                        onSearchChange: (event) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(""),
                                        pageSize: pageSize,
                                        onPageSizeChange: (event) => setPageSize(Number(event.target.value)),
                                        rowsPerPageOptions: [5, 10, 20, 50, 100]
                                    }
                                }}

                                pageSize={pageSize}
                                autoHeight
                                loading={loading}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                </div>
            </div>

            {loading && <Loader />}
        </>
    );
}

export default Statistics;