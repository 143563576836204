import moment from "moment"
import { toast } from "react-toastify"
import { IFolderAndFile } from "../api/document-api"
import { IConfig } from "../hooks/useConfig"
import { AdminRoutes } from "../routes"

class Helper {

    static getTitleBar(pathname: string) {
        if (pathname === "/admin") return "Dashboard"
        else {
            const array: any[] = []
            AdminRoutes.forEach(item => {
                const obj = {
                    name_var: item.name_var,
                    url_var: item.url_var,
                    icon_var: item.icon_var,
                }
                array.push(obj)
                if (item.children && item.children.length > 0) {
                    item.children?.forEach(item_1 => {
                        const obj_1 = {
                            name_var: item_1['name_var'],
                            url_var: item_1['url_var'],
                            icon_var: item_1['icon_var'],
                        }
                        array.push(obj_1)
                    })
                }
            })
            return array.find(data => pathname === '/admin/' + data.url_var)?.name_var
        }
    }

    static getRoleName(roleID: number) {
        switch (roleID) {
            case 1:
                return "Administrator"
            case 2:
                return "User"
            case 99:
                return "Super Admin"
            default:
                return "Administrator"
        }
    }

    static bulkResponseError(res: any) {
        if (res.data) {
            if (res.data.data) {
                try {
                    if (res.data.data.error) {
                        toast.error(res.data.data.error)
                    } else {
                        if (res.data.data.length > 0) {
                            Object.keys(res.data.data).forEach(item => {
                                toast.error(res.data.data[item][0])
                            })
                        } else {
                            toast.error(res.data.message)
                        }
                    }
                } catch (error) {
                    toast.error(res.data.message || res?.status + ' ' + res?.statusText || 'Connection Timeout')
                }
            } else {
                toast.error(res.data.message || res?.status + ' ' + res?.statusText || 'Connection Timeout')
            }
        } else {
            toast.error('Connection Timeout')
        }
    }

    static generateBreatCrumbItem(pathname: string) {
        let res: any[] = []
        if (pathname === '/root') {
            res = []
        } else {
            const a = pathname.replace('/root/', '').split('/')
            let lastPath = ''
            a.forEach(path => {
                const obj = {
                    name: path,
                    path: lastPath + '/' + path
                }
                lastPath += `/${path}`
                res.push(obj)
            })
        }

        return res
    }

    static convertExtensionToIcon = (item: string | null | undefined) => {
        if (!item) { return null; }
        const extension: string | null = item.split('.').length > 1 ? item.split('.')[item.split('.').length - 1] : item;

        switch (extension ? extension.toLowerCase() : null) {
            case 'pdf':
                return {
                    iconClass: 'bi bi-file-earmark-pdf-fill',
                    type: 'Data'
                };
            case 'ppt': case 'pptx':
                return {
                    iconClass: 'bi bi-file-earmark-ppt-fill',
                    type: 'Data'
                };
            case 'xlsx': case 'xls':
                return {
                    iconClass: 'bi bi-file-earmark-excel-fill',
                    type: 'Data'
                };
            case 'jpg': case 'png': case 'jpeg': case 'bmp': case 'gif':
                return {
                    iconClass: 'bi bi-image-fill',
                    type: 'Image'
                };
            case 'doc': case 'docx':
                return {
                    iconClass: 'bi bi-file-earmark-word-fill',
                    type: 'Text'
                };
            case 'txt':
                return {
                    iconClass: 'bi bi-file-earmark-text-fill',
                    type: 'Text'
                };
            case 'mp3': case 'mp4': case 'mkv':
                return {
                    iconClass: 'bi bi-play-circle-fill',
                    type: 'Text'
                };
            case '"directory"':
                return {
                    iconClass: 'bi bi-folder-fill',
                    type: 'Folder'
                };
            case null:
                return {
                    iconClass: 'bi bi-folder-fill',
                    type: 'Folder'
                };
            default:
                return {
                    iconClass: 'bi bi-file-earmark-fill',
                    type: 'Folder'
                };
        };
    };

    static orderFileFolder = (array: IFolderAndFile[], key: string, typeOrder: 'asc' | 'desc' | string, measure?: string) => {
        if (key === 'name') {
            if (typeOrder === 'asc') {
                return array.sort((a, b) => a[key]!.localeCompare(b[key]!))
            } else {
                return array.sort((b, a) => a[key]!.localeCompare(b[key]!))
            }
        } else if (key === 'last_modified') {
            if (typeOrder === 'asc') {
                return array.sort((b, a) => moment(a[key], moment(a[key]).creationData().format).valueOf() - moment(b[key], moment(a[key]).creationData().format).valueOf())
            } else {
                return array.sort((a, b) => moment(a[key], moment(a[key]).creationData().format).valueOf() - moment(b[key], moment(a[key]).creationData().format).valueOf())
            }
        } else if (key === 'size') {
            if (typeOrder === 'asc') {
                return array.sort((b, a) => Number(a[key]) - Number(b[key]))
            } else {
                return array.sort((a, b) => Number(a[key]) - Number(b[key]))
            }
        } else {
            return array
        }
    }

    static indonesiaPhone = (phoneNumber: string) => {
        try {
            var msisdn = ""
            if (phoneNumber !== undefined) {
                if (phoneNumber.charAt(0) === "0") {
                    msisdn = "62" + (phoneNumber.substring(1, phoneNumber.length))
                } else {
                    msisdn = phoneNumber
                }
            } else {
                msisdn = phoneNumber
            }

            return msisdn
        } catch (error) {
            return phoneNumber
        }
    }

    static getConfigByCode = (configList: IConfig[], configCode: string) => {
        if (configList!.length === 0) {
            return ''
        }
        return configList.find(item => item.code === configCode)?.value || ''
    }

    static fileToBase64 = (file: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    static blobToBase64(blob: any) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    static toDataURL(url: string) {
        return fetch(url).then((response) => {
            return response.blob();
        }).then(blob => {
            return URL.createObjectURL(blob);
        });
    }

    static async download(url: string, filename: string) {
        var link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export default Helper