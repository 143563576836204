import React, { useEffect, useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProfileMenu from '../components/ProfileMenu';
import Sidebar from '../components/Sidebar';
import NotFound from '../pages/NotFound';
import { dashboardRoutes } from '../routes';
import Helper from '../utils/Helper';
import icon from '../assets/img/favicon.ico'
import { IConfig } from '../hooks/useConfig';
import { useRecoilValue } from 'recoil';
import { configurationState } from '../atoms/recoilState';

// MAIN ROUTE
const getRoutes = () => {
	return dashboardRoutes.map((data, key) => {
		return <Route path={data.path} element={data.component} key={key} />
	})
}

type Props = {};

const Dashboard: React.FC<Props> = () => {
	
	const localConfig: IConfig[] = localStorage.getItem('config')? JSON.parse(localStorage.getItem('config') || '') : []
	const recoilConfigState = useRecoilValue(configurationState)

	const config = recoilConfigState.length>0? [...recoilConfigState] : localConfig

    const token = localStorage.getItem('authToken')
    const user = useMemo(() => localStorage.getItem('dataUser')? JSON.parse(localStorage.getItem('dataUser') || '') : {}, [])

	const {pathname} = useLocation()
	const navigate = useNavigate()

	useEffect(() => {
		if(!token) navigate('/auth', {replace: true})
		if(user && parseInt(user.role)!==1){
			navigate('/root', {replace: true})
		}
	}, [navigate, token, user])

    return (
        <>
            <div className='flex flex-col md:flex-row w-full h-screen overflow-hidden font-poppins bg-soft text-dark font-medium'>
				<Sidebar />
				<div className='w-full overflow-x-hidden overflow-y-auto h-screen flex flex-col'>
					
					{/* HEADER */}
					<div className='hidden md:flex items-center h-[5.5rem] min-h-[5.5rem] bg-white pl-10 pr-5 sticky top-0 z-10'>
						<div className='flex w-full justify-between items-center z-40'>
							<h1 className='font-bold text-2xl'>{Helper.getTitleBar(pathname)}</h1>
							{/* <h1 className='font-bold text-2xl'>daskdgash</h1> */}
							<div className='flex items-center'>
								<ProfileMenu />
								{/* <LazyLoadImage effect='blur' src={appLogo} className='border-l ml-2 pl-2 w-9' /> */}
								<i className='bi bi-folder2-open border-l ml-2 pl-2 text-3xl cursor-pointer hover:text-orange-400' onClick={() => navigate('/root')}></i>
							</div>
						</div>
					</div>
					
					{/* DASHBOARD CONTENT */}
						<Routes>
							{getRoutes()}
	
							<Route path='*' element={<NotFound />} /> 
							<Route path='/' element={<Navigate replace to='/dashboard' />} />
	
						</Routes>
	
					
					{/* FOOTER */}
					<div className='flex items-center justify-between bg-white p-5 px-3 md:px-5 mt-auto text-xs md:text-base'>
						<p>{Helper.getConfigByCode(config, 'C000')} © {new Date().getFullYear()}</p>
						<div className='flex items-center'>
							<LazyLoadImage effect='blur' alt='' src={icon} className='w-5 mr-1'  />
							<p>v1.0</p>
						</div>
					</div>
	
				</div>

				<div className='text-sm'>
				<ToastContainer
					position='bottom-left'
					theme='dark'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover />
				</div>
			</div>
        </>
    );
}

export default Dashboard;