import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { IFolderAndFile } from '../api/document-api';
import DropdownGroupAction from '../components/DropdownGroupAction';
import ReactTable from '../components/ReactTable';
import SearchField from '../components/SearchField';
import SortFileDirectory from '../components/SortFileDirectory';
import Helper from '../utils/Helper';

type Props = {
    data: IFolderAndFile[],
    onDoubleClick: (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLButtonElement, MouseEvent>, data: IFolderAndFile, type: 'file' | 'folder') => any,
    onContextMenu: (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLButtonElement, MouseEvent>, data: IFolderAndFile, type: 'file' | 'folder') => any,
    onSingleAction: (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLButtonElement, MouseEvent>, data: IFolderAndFile, type: 'file' | 'folder') => any,
    onContextMenuCurrentDir?: (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLButtonElement, MouseEvent>) => any,
    onPaste?: (e: React.MouseEvent<HTMLElement>) => any,
    showPaste?: boolean,
    onGroupMove: (data: IFolderAndFile[]) => any
    onGroupCopy: (data: IFolderAndFile[]) => any
    onGroupDownload: (data: IFolderAndFile[]) => any
    onGroupShare: (data: IFolderAndFile[]) => any
    onRenameSelection: (data: IFolderAndFile) => any
    onGroupDelete: (data: IFolderAndFile[]) => any
};

const Files: React.FC<Props> = ({ data, onDoubleClick, onContextMenu, onSingleAction, onContextMenuCurrentDir, showPaste, onPaste, onGroupMove, onGroupCopy, onGroupDownload, onGroupShare, onRenameSelection, onGroupDelete  }) => {
    const [filterData, setFilterData] = useState<IFolderAndFile[] | []>(data)
    const [searchValue, setSearchValue] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [sortType, setSortType] = useState<'asc' | 'desc' | string>('asc')
    const [isGrid, setIsGrid] = useState(false)
    const [selectedRows, setSelectedRows] = useState<any[]>([])

    // const isMobile = useMobileView()

    useEffect(() => {
        const newData = [...data]
        if (searchValue) {
            const filtered = newData.filter((item: IFolderAndFile) => {
                return (
                    item?.name?.toLowerCase().includes(searchValue.toLowerCase())
                )
            });

            setFilterData(filtered)
        } else {
            setFilterData(data)
        }
    }, [searchValue, data])

    useEffect(() => {
        setSearchValue('')
    }, [data])

    useEffect(() => {
        if (sortBy) {
            const noSortData = [...filterData]
            const sortData = Helper.orderFileFolder(noSortData, sortBy, sortType)
            setFilterData(sortData)
        } else {
            setFilterData(data)
        }
        // eslint-disable-next-line
    }, [sortBy, sortType])

    const onRowSelected = (rows: any[]) => {
        setSelectedRows(rows)
    }

    const COLUMNS = useMemo(() => [
        {
            Header: () => <span className='p-4'>Name</span>,
            accessor: 'name',
            width: 350,
            Cell: ({ value, row }: any) => {
                const data = row.original
                return (
                    <div className='text-left pl-4 flex items-center'>
                        <i className={`${Helper.convertExtensionToIcon(data.extension)?.iconClass} flex items-center mr-1.5 text-xl`}></i>
                        <p>{value}</p>
                    </div>
                )
            },
        },
        {
            Header: 'Date modified',
            accessor: 'last_modified',
            width: 170,
            Cell: ({ value }: any) => {
                return moment(value).format('DD-MM-yyyy HH:mm:ss')
            }
        },
        {
            Header: 'Type',
            accessor: 'extension',
            width: 50
        },
        {
            Header: 'Size',
            accessor: 'size',
            width: 100,
            Cell: ({ row }: any) => {
                const data = row.original
                return data?.size + ' ' + data?.measure
            }
        },
        {
            Header: 'Action',
            accessor: 'action',
            width: 50,
            Cell: ({ row }: any) => {
                const data = row.original
                // return <DropdownSingleAction type='file' />
                return (
                    <button onClick={(e) => onSingleAction(e, data, 'file')} className="relative z-5 rounded-md focus:outline-none flex items-center bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-400 text-white px-2.5 py-1">
                        <i className="ri-settings-line text-lg"></i>
                    </button>
                )
            }
        }
    ], [onSingleAction])

    return (
        <div onContextMenu={onContextMenuCurrentDir}>
            <div className='flex items-center justify-between border-b border-slate-300 dark:border-slate-800'>
                <h3 className='font-bold text-slate-700 dark:text-slate-100 w-full'>Files</h3>
                <SearchField placeholder='Search file...' onChange={(e) => setSearchValue(e.target.value)} clearSearch={() => setSearchValue('')} value={searchValue} />
            </div>

            {showPaste &&
                <div className='flex item-center justify-center mt-7 -mb-2'>
                    <button className='flex item-center bg-slate-200 dark:bg-slate-800 hover:bg-white dark:hover:bg-slate-700 text-slate-700 dark:text-slate-300 hover:text-slate-800 dark:hover:text-slate-100 border-2 border-slate-400 dark:border-slate-700 hover:border-slate-500 dark:hover:border-slate-100 rounded-lg px-3 py-2 focus:ring-2 focus:ring-offset-1 focus:ring-white' onClick={onPaste}>
                        <i className='bi bi-clipboard-fill text-lg flex items-center mr-2'></i>
                        <p>Paste clipboard item here!</p>
                    </button>
                </div>
            }

            {filterData.length > 0 ?
                <>

                    {isGrid ?
                        <>
                            <div className='flex item-center justify-between my-5'>
                                <button className='btn-primary w-8 h-8 p-0 text-xl rounded-md' onClick={() => setIsGrid(!isGrid)}>
                                    {isGrid ? <i className='bi bi-grid-fill'></i> : <i className='bi bi-list-check'></i>}
                                </button>

                                <SortFileDirectory
                                    sortByValue={sortBy} onSortByChange={(e) => setSortBy(e.target.value)}
                                    sortTypeValue={sortType} onSortTypeChange={(e) => setSortType(e.target.value)}
                                />
                            </div>
                            <div className='grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 z-0' onContextMenu={onContextMenuCurrentDir}>
                                {
                                    filterData.map((data, key) =>
                                        <button key={key} onDoubleClick={(e) => onDoubleClick(e, data, 'file')} onContextMenu={(e) => onContextMenu(e, data, 'file')} /* onClick={(e) => isMobile && onContextMenu(e, data, 'file')} */
                                            className='text-slate-600 hover:text-slate-700 dark:text-slate-300 hover:dark:text-slate-100 border-2 border-transparent hover:border-slate-500 dark:hover:border-slate-100 w-40 h-[7rem] rounded-xl flex flex-col items-center justify-end font-medium m-1 lg:m-3 z-10'>
                                            <i className={`${Helper.convertExtensionToIcon(data.extension)?.iconClass} text-6xl`}></i>
                                            <p className='mb-2 mt-1.5'>{data.name!.length > 17 ? data.name?.substring(0, 16) + '...' : data.name}</p>
                                        </button>
                                    )}
                            </div>
                        </>
                        :
                        <>
                            <div className='flex item-center justify-between my-5'>
                                <button className='btn-primary w-8 h-8 p-0 text-xl rounded-md shadow-lg' onClick={() => setIsGrid(!isGrid)}>
                                    {isGrid ? <i className='bi bi-grid-fill'></i> : <i className='bi bi-list-check'></i>}
                                </button>
                                {selectedRows.length > 0 &&
                                    <DropdownGroupAction 
                                        items={selectedRows} type='file' 
                                        onCopy={()=>onGroupCopy(selectedRows.map(row => row.original))}
                                        onMove={()=>onGroupMove(selectedRows.map(row => row.original))}
                                        onShare={()=>onGroupShare(selectedRows.map(row => row.original))}
                                        onDownload={()=>onGroupDownload(selectedRows.map(row => row.original))}
                                        onDelete={()=>onGroupDelete(selectedRows.map(row => row.original))}
                                        onRename={()=>onRenameSelection(selectedRows[0].original)}
                                    />
                                }
                            </div>
                            <ReactTable 
                                dataTable={filterData} 
                                columnTable={COLUMNS} 
                                selection={true} 
                                // onClickRow={(e: any, row: IFolderAndFile) => isMobile && onContextMenu(e, row, 'file')} 
                                onDoubleClickRow={(e: any, row: IFolderAndFile) => onDoubleClick(e, row, 'file')} 
                                onRightClick={(e: any, row: IFolderAndFile) => onContextMenu(e, row, 'file')} 
                                onRowSelect={onRowSelected} 
                            />
                        </>
                    }
                </>
                :
                <div className='flex items-center justify-center h-32'>
                    <p>No file exist</p>
                </div>
            }
        </div>
    );
}

export default Files;