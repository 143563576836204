import {
    useGridApiContext,
    gridPageCountSelector,
    gridPageSelector,
    useGridSelector,
} from '@mui/x-data-grid';

import { Pagination } from '@mui/material';

function DataGridPagination({countData, title}: any) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <div className='w-full flex justify-between items-center'>
        <p className='pl-2'>Total <span className='font-semibold'>{countData}</span> {title}</p>        
        <Pagination
            sx={(theme) => ({
                padding: theme.spacing(1.5, 0),
                // color: 'black',
                // "& .MuiPaginationItem-root": {
                //     color: "#fff"
                // }
            })}
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
        </div>

    );
}

export default DataGridPagination