import React from 'react';

type Props = {
    sortByValue: string,
    sortTypeValue: string,
    onSortByChange: (e: React.ChangeEvent<HTMLSelectElement>) => any,
    onSortTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => any,
};

const SortFileDirectory: React.FC<Props> = ({ sortByValue, sortTypeValue, onSortByChange, onSortTypeChange }) => {
    return (
        <>
            <div className='flex justify-end'>
                <div className='my-2'>
                    <select onChange={onSortByChange} value={sortByValue} className='outline-none border-none'>
                        <option value=''>- Sort -</option>
                        <option value='name'>Name</option>
                        <option value='last_modified'>Date Modified</option>
                        <option value='size'>Size</option>
                    </select>
                    {sortByValue &&
                    <select onChange={onSortTypeChange} value={sortTypeValue} className='outline-none border-none ml-2'>
                        <option value='asc'>Asc</option>
                        <option value='desc'>Desc</option>
                    </select>
                    }
                </div>
            </div>
        </>
    );
}

export default SortFileDirectory;