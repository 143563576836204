import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { updateConfig } from '../../api/common-api';
import { configurationState } from '../../atoms/recoilState';
import Loader from '../../components/Loader';
import useConfig, { IConfig } from '../../hooks/useConfig';
import Helper from '../../utils/Helper';

type Props = {};

const GeneralSetting: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)

    const editorRef = useRef<any>(null);
    const setRecoilConfigState = useSetRecoilState(configurationState)

    const localConfig: IConfig[] = localStorage.getItem('config')? JSON.parse(localStorage.getItem('config') || '') : []

    const { config } = useConfig(localConfig)

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        showLoader(true)
        const {
            C000, C038, C039, C040, C041, C042, C043, C044, C045, C046, C047, C048
        } = e.target

        const payload: IConfig[] = [
            {
                "code": "C000",
                "value": C000?.value,
            },
            {
                "code": "C021",
                "value": editorRef.current?.getContent(),
            },
            {
                "code": "C038",
                "value": C038?.value,
            },
            {
                "code": "C039",
                "value": C039?.value,
            },
            {
                "code": "C040",
                "value": C040?.value,
            },
            {
                "code": "C041",
                "value": C041?.value,
            },
            {
                "code": "C042",
                "value": C042?.value,
            },
            {
                "code": "C043",
                "value": C043?.value,
            },
            {
                "code": "C044",
                "value": C044?.value,
            },
            {
                "code": "C045",
                "value": C045?.value,
            },
            {
                "code": "C046",
                "value": C046?.value,
            },
            {
                "code": "C047",
                "value": C047?.value,
            },
            {
                "code": "C048",
                "value": C048?.value,
            },
        ]
        
        const res = await updateConfig(payload)

        showLoader(false)
        console.log("Update config :", res)
        if(res.status===200){
            localStorage.setItem('config', JSON.stringify(payload))
            setRecoilConfigState(payload)
            toast.success(res.data.message)
        }else{
            Helper.bulkResponseError(res)
        }
    }

    return (
        <>
            <div className='p-6'>
                <form className='text-slate-500 bg-white shadow-lg rounded-lg p-5 lg:p-6' onSubmit={handleSubmit}>
                    <div className='mb-5 flex items-center text-base md:text-xl'>
                        <i className="ri-settings-6-fill text-lg md:text-2xl mr-2 md:mr-3"></i>
                        <h1 className='font-bold'>General Setting</h1>
                    </div>

                    {config.length > 0 ?
                        <div className='w-full flex flex-col space-y-7'>
                            <div>
                                <label htmlFor="C000" className="label-setting">The title of your file manager</label>
                                <input type="text" id="C000" name='C000' className="input-setting" placeholder="App's title" defaultValue={Helper.getConfigByCode(config!, 'C000')} />
                            </div>
                            <div>
                                <label className="label-setting mb-2">Description (optional)</label>
                                <Editor
                                    textareaName='C021'
                                    apiKey='0ev2sflu6mkuy7h4j8rnxuxu9ogu9hetctj4cm36bqddv4pm'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    initialValue={Helper.getConfigByCode(config!, 'C021')}
                                    init={{
                                        height: 200,
                                        menubar: false,
                                        toolbar_mode: 'floating',
                                        tinycomments_mode: 'embedded',
                                        tinycomments_author: 'Ichsan Kurnia',
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar: 'formatselect | ' +
                                            'bold italic underline backcolor | link | alignleft aligncenter ' +
                                            'alignright alignjustify | code | preview',
                                        // skin: useDarkMode ? 'oxide-dark' : 'oxide',
                                        // content_css: useDarkMode ? 'dark' : 'default',
                                        skin: 'oxide-dark',
                                        content_css: 'dark',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                                    }}
                                />
                            </div>
                            <div>
                                <div className='flex item-center font-semibold mb-2'>
                                    <i className="bi bi-file-earmark-zip-fill mr-2"></i>
                                    <p>Upload</p>
                                </div>
                                <div className='flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0'>
                                    <div className='w-full'>
                                        <label htmlFor="C038" className="label-setting">Allowed Extensions</label>
                                        <input type="text" id="C038" name='C038' className="input-setting" placeholder="jpg,jpeg,gif,png" defaultValue={Helper.getConfigByCode(config!, 'C038')} />
                                    </div>
                                    <div>
                                        <label htmlFor="C039" className="label-setting">Max file size</label>
                                        <div className='flex items-center'>
                                            <input type="number" id="C039" className="input-setting mr-1" placeholder="1024" defaultValue={Helper.getConfigByCode(config!, 'C039')} />
                                            <p>MB</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex item-center font-semibold mb-2'>
                                    <i className="bi bi-file-earmark-zip-fill mr-2"></i>
                                    <p>Whatsapp</p>
                                </div>
                                <div className='flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0'>
                                    <div className='w-full'>
                                        <label htmlFor="C040" className="label-setting">Template text file sharing</label>
                                        <textarea id="C040" name='C040' className="input-setting" placeholder="Template text for file sharing..." defaultValue={Helper.getConfigByCode(config!, 'C040')} />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="C041" className="label-setting">Template text Customer Service</label>
                                        <textarea id="C041" name='C041' className="input-setting" placeholder="Template chat to customer service..." defaultValue={Helper.getConfigByCode(config!, 'C041')} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex item-center font-semibold mb-2'>
                                    <i className="bi bi-file-earmark-zip-fill mr-2"></i>
                                    <p>Email</p>
                                </div>
                                <div className='flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0'>
                                    <div className='w-full'>
                                        <label htmlFor="C042" className="label-setting">Subject</label>
                                        <input type="text" id="C042" name='C042' className="input-setting" placeholder="Subject of email" defaultValue={Helper.getConfigByCode(config!, 'C042')} />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="C043" className="label-setting">Title</label>
                                        <input type="text" id="C043" name='C043' className="input-setting" placeholder="The title of email" defaultValue={Helper.getConfigByCode(config!, 'C043')} />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="C044" className="label-setting">Body</label>
                                        <textarea id="C044" name='C044' className="input-setting" rows={3} placeholder="The body of email" defaultValue={Helper.getConfigByCode(config!, 'C044')} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex item-center font-semibold mb-2'>
                                    <i className="bi bi-file-earmark-zip-fill mr-2"></i>
                                    <p>Public Folder</p>
                                </div>
                                <div className='flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0'>
                                    <div className='w-full'>
                                        <label htmlFor="C045" className="label-setting">Public Folder for Storage Link Download File</label>
                                        <input type="text" id="C045" name='C045' className="input-setting" placeholder="public" defaultValue={Helper.getConfigByCode(config!, 'C045')} />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='flex item-center font-semibold mb-2'>
                                    <i className="bi bi-file-earmark-zip-fill mr-2"></i>
                                    <p>Domain Endpoint Apps</p>
                                </div>
                                <div className='flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0'>
                                    <div className='w-full'>
                                        <label htmlFor="C046" className="label-setting">End Point Web Efaktur</label>
                                        <input id="C046" name='C046' className="input-setting" placeholder="https://domain-name.com" defaultValue={Helper.getConfigByCode(config!, 'C046')} />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="C047" className="label-setting">End Point Link Reset Password Web Efaktur</label>
                                        <input id="C047" name='C047' className="input-setting" placeholder="https://domain-name.com/reset-password" defaultValue={Helper.getConfigByCode(config!, 'C047')} />
                                    </div>
                                </div>
                                <div className='flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-4 lg:space-y-0 mt-4'>
                                    <div className='w-full'>
                                        <label htmlFor="C048" className="label-setting">Link Download APK</label>
                                        <input id="C048" name='C048' className="input-setting" placeholder="https://domain-name.com/reset-password" defaultValue={Helper.getConfigByCode(config!, 'C048')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <Loader title='Loading configuration...' />
                    }


                    <div className='w-full border-t border-slate-300 flex justify-end mt-8 px-5 pt-4'>
                        <button type='submit' className='btn-primary px-3 py-1 text-2xl'>
                            <i className='ri-save-3-fill'></i>
                        </button>
                    </div>

                </form>

            </div>
            {loader && <Loader />}
        </>
    );
}

export default GeneralSetting;