import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthRoutes } from '../routes';
import NotFound from '../pages/NotFound';
import { Fab } from 'react-tiny-fab';
import ContactCSModal from '../components/modals/ContactCSModal';
import bgLogin from '../assets/img/bg-login.jpg'

// MAIN ROUTE
const getRoutes = () => {
    return AuthRoutes.map((data, key) => {
        return <Route path={data.path} element={data.component} key={key} />
	})
}

type Props = {};

const Auth: React.FC<Props> = () => {
    const [csModal, showCSModal] = useState(false)

    return (
        <>
            <div className="bg-[#eee] min-h-screen flex justify-center items-center font-poppins" draggable={false}>
				<div className="relative w-[1366px] h-[768px]">

					<div className="absolute top-0 left-0 w-full md:w-1/2 h-full bg-white 2xl:rounded-tl-xl 2xl:rounded-bl-xl text-dark z-10">
                        <Routes>
							{getRoutes()}
	
							<Route path='*' element={<NotFound />} /> 
                            <Route path='/' element={<Navigate replace to='/auth/sign-in' />} />
	
						</Routes>
					</div>

					{/* <div className="hidden md:block h-full w-full object-fill z-0"> */}
						<LazyLoadImage src={bgLogin} alt="" className='hidden md:block h-full w-full object-fill z-0 2xl:rounded-xl' />
					{/* </div> */}

				</div>
			</div>
            <Fab
                mainButtonStyles={{backgroundColor: '#fb923c'}}
                icon={<i className='ri-customer-service-2-line text-3xl'></i>}
                // alwaysShowTitle={true}
                text="Customer Service"
                onClick={()=>showCSModal(true)}
            >
            </Fab>

            {csModal && <ContactCSModal onClose={() => showCSModal(false)} /> }
        </>
    );
}

export default Auth;