import React from 'react';
import createDOMPurify from "dompurify";

const DOMPurify = createDOMPurify(window);

type Props = {
    onClose?: (e: React.MouseEvent<HTMLElement>) => void,
    data?: string,
};

const InformationModal: React.FC<Props> = ({ onClose, data }) => {

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-7/12 2xl:w-6/12 text-slate-700 dark:text-white rounded-lg shadow bg-soft dark:bg-slate-800 p-6 lg:px-8 z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-center rounded-t border-b pb-3 border-slate-300 dark:border-gray-600 mb-5">
                        <div className="text-base md:text-xl font-semibold text-slate-700 dark:text-white flex item-center space-x-2 lg:space-x-3">
                            <i className="bi bi-info-circle"></i>
                            <h3>Information</h3>
                        </div>
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    {data?
                    <div className='flex'>
                        <div className='lg:text-base text-slate-700 dark:text-slate-200' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data)}}>
                        </div>
                    </div>
                    :
                    <div className='text-slate-100 font-medium flex justify-center items-center h-40'>
                        <svg className="animate-spin mr-3 h-5 w-5 text-slate-700 dark:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <p>Loading information...</p>
                    </div>
                    }

                    <div className="flex items-center mt-7 pt-4 space-x-4 rounded-b border-t border-slate-300 dark:border-gray-600">
                        <button type='reset' className="btn-primary" onClick={onClose}>Close</button>
                    </div>

                </div>
            </div>
        </>
    );
}

export default InformationModal;