import MainDashboard from "./pages/dashboard/MainDashboard"
import Login from "./pages/auth/Login"
import Register from "./pages/auth/Register"
import UserManagement from "./pages/dashboard/UserManagement"
import CustomerServiceManagement from "./pages/dashboard/CSManagement"
import Preference from "./pages/dashboard/Preference"
import Statistics from "./pages/dashboard/Statistics"
import GeneralSetting from "./pages/dashboard/GeneralSetting"
import ForgotPassword from "./pages/auth/ForgotPassword"


export const AdminRoutes = [
    {
        name_var: 'Dashboard',
        url_var: '',
        icon_var: 'bi bi-house-fill'
    },
    {
        name_var: 'General Settings',
        url_var: 'preference-general-setting',
        icon_var: 'ri-settings-6-fill',
    },
    // {
    //     name_var: 'Preference',
    //     url_var: 'preference',
    //     icon_var: 'ri-equalizer-fill',
    //     children: [
    //         {
    //             name_var: 'General Settings',
    //             url_var: 'preference-general-setting',
    //             icon_var: 'ri-settings-6-fill',
    //         },
    //         {
    //             name_var: 'Lists',
    //             url_var: 'preference-lists',
    //             icon_var: 'ri-list-settings-fill',
    //         },
    //         {
    //             name_var: 'File Sharing',
    //             url_var: 'preference-file-sharing',
    //             icon_var: 'ri-share-fill',
    //         },
    //         {
    //             name_var: 'Permissions',
    //             url_var: 'preference-permissions',
    //             icon_var: 'bi bi-shield-fill-exclamation',
    //         },
    //         {
    //             name_var: 'Registration',
    //             url_var: 'preference-registration',
    //             icon_var: 'ri-user-add-fill',
    //         },
    //         {
    //             name_var: 'E-Mail',
    //             url_var: 'preference-email',
    //             icon_var: 'ri-mail-settings-fill',
    //         },
    //         {
    //             name_var: 'Activity Log',
    //             url_var: 'preference-activity-log',
    //             icon_var: 'bi bi-graph-up-arrow',
    //         },
    //         {
    //             name_var: 'Appearance',
    //             url_var: 'preference-appearance',
    //             icon_var: 'bi bi-palette-fill',
    //         }
    //     ]
    // },
    {
        name_var: 'User Management',
        url_var: 'user-management',
        icon_var: 'bi bi-people-fill',
        children: []
    },
    {
        name_var: 'Customer Service Management',
        url_var: 'cs-management',
        icon_var: 'ri-customer-service-2-fill',
        children: []
    },
    // {
    //     name_var: 'Translations',
    //     url_var: 'product-category',
    //     icon_var: 'bi bi-translate',
    //     children: []
    // },
    {
        name_var: 'Statistics',
        url_var: 'statistics',
        icon_var: 'ri-pie-chart-2-fill',
        children: []
    }
]



export const dashboardRoutes = [
    {
        path: '',
        component: <MainDashboard />
    },
    {
        path: 'preference',
        component: <Preference />
    },
    {
        path: 'preference-general-setting',
        component: <GeneralSetting />
    },
    {
        path: 'user-management',
        component: <UserManagement />
    },
    {
        path: 'cs-management',
        component: <CustomerServiceManagement />
    },
    {
        path: 'statistics',
        component: <Statistics />
    },
]


export const AuthRoutes = [
    {
        path: 'sign-in',
        component: <Login />
    },
    {
        path: 'sign-up',
        component: <Register />
    },
    {
        path: 'forgot-password',
        component: <ForgotPassword />
    },
    // {
    //     path: 'otp-validate',
    //     component: <OtpPage />
    // },
]