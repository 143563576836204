import axios from "axios"
import { baseURL } from "./common-api"

interface IDocumentPayload {
    path_folder?: string,
    path_file?: string | string[],
}

interface ICPMVPayload {
    new_path_file?: string | string[],
    old_path_file?: string | string[]
}

export interface IFolderAndFile {
    name?: string,
    path?: string,
    extension?: string,
    last_modified?: number,
    size?: string,
    measure?: string
}

class DocumentAPI{

    static showFolder = async (path: string) => {
        try {
            const payload: IDocumentPayload = {
                path_folder: path
            }

            const data = await axios.post(`${baseURL}/folders/v2`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static showFile = async (path: string) => {
        try {
            const payload: IDocumentPayload = {
                path_folder: path
            }
            
            const data = await axios.post(`${baseURL}/files`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static showFolderAndFile = async (path: string) => {
        try {
            const payload: IDocumentPayload = {
                path_folder: path
            }
            
            const data = await axios.post(`${baseURL}/folders/files`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static createFolder = async (path: string) => {
        try {
            const payload: IDocumentPayload = {
                path_folder: path
            }
            
            const data = await axios.post(`${baseURL}/folders/create`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static deleteFolder = async (path: string) => {
        try {
            const payload: IDocumentPayload = {
                path_folder: path
            }
            
            const data = await axios.post(`${baseURL}/folders/delete`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }


    static rename = async (newPath: string, oldPath: string) => {
        try {
            const payload: ICPMVPayload = {
                new_path_file: newPath,
                old_path_file: oldPath
            }
            
            const data = await axios.post(`${baseURL}/files/rename`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static uploadFile = async (payload: any) => {
        try {
            const data = await axios.post(`${baseURL}/files/upload`, payload, {
            // const data = await axios.post(`${baseURL}/files/upload/multiple`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    // 'Content-Type': 'multipart/form-data'
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
    
    static downloadFile = async (path: string[]) => {
        try {
            const payload: IDocumentPayload = {
                path_file: path
            }
            
            const data = await axios.post(`${baseURL}/files/download`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                // responseType: 'blob'
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static shareFileLink = async (path: string[]) => {
        try {
            const payload: IDocumentPayload = {
                path_file: path
            }
            
            const data = await axios.post(`${baseURL}/files/share`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static shareFileEmail = async (email: string, path: string[]) => {
        try {
            const payload = {
                mail_to: email,
                attachment: path
            }
            
            const data = await axios.post(`${baseURL}/files/share/email`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static deleteFile = async (path: string[]) => {
        try {
            const payload: IDocumentPayload = {
                path_file: path
            }
            
            const data = await axios.post(`${baseURL}/files/delete`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static moveFile = async (newPath: string[], oldPath: string[]) => {
        try {
            const payload: ICPMVPayload = {
                new_path_file: newPath,
                old_path_file: oldPath
            }
            
            const data = await axios.post(`${baseURL}/files/move`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }

    static copyFile = async (newPath: string[], oldPath: string[]) => {
        try {
            const payload: ICPMVPayload = {
                new_path_file: newPath,
                old_path_file: oldPath
            }
            
            const data = await axios.post(`${baseURL}/files/copy`, payload, {
                headers: {
                    authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            return data
        } catch (error: any) {
            if(error.response) return error.response
            else return JSON.parse(JSON.stringify(error))  
        }
    }
}

export default DocumentAPI
