import React from 'react';

type Props = {
    ofset: any,
    type?: 'file' | 'folder'
    actionType?: string | null,
    onOpen?: (e: React.MouseEvent<HTMLElement>) => void
    onUpload?: (e: React.MouseEvent<HTMLElement>) => void
    onMove?: (e: React.MouseEvent<HTMLElement>) => void
    onCopy?: (e: React.MouseEvent<HTMLElement>) => void
    onPaste?: (e: React.MouseEvent<HTMLElement>) => void
    onDownload?: (e: React.MouseEvent<HTMLElement>) => void
    onShare?: (e: React.MouseEvent<HTMLElement>) => void
    onRename?: (e: React.MouseEvent<HTMLElement>) => void
    onDelete?: (e: React.MouseEvent<HTMLElement>) => void
};

const ContextMenu: React.FC<Props> = ({ ofset, type, actionType, onOpen, onMove, onCopy, onPaste, onDownload, onShare, onRename, onDelete }) => {
    const user = localStorage.getItem('dataUser') ? JSON.parse(localStorage.getItem('dataUser') || '') : {}
    const role = user ? parseInt(user.role) : 99

    const width = window.screen.width
    const height = window.screen.height

    const divHeigth = type==='file'?225:136

    // const ref = useRef<any>(null)
    // console.log(ref.current?.offsetHeight)

    return (
        <>
            <div className="origin-top-right absolute w-max bg-soft dark:bg-slate-700 border dark:border-slate-600 text-slate-600 dark:text-slate-200 py-1.5 px-1 rounded shadow-lg overflow-hidden"
                style={{ top: ofset.top + 64 + divHeigth > height ? height - divHeigth - 64 : ofset.top, left: ofset.left + 151 > width ? width - 151 : ofset.left, zIndex: 9999 }}>
                {/* style={{top: top, left: ofset.left }}> */}
                {/* style={ofset}> */}
                {role === 1 ?
                    <ul>
                        <li>
                            <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onOpen}>
                                <i className="ri-book-open-line mr-3"></i>Open
                            </button>
                        </li>
                        <div className='w-full h-[1px] bg-slate-300 dark:bg-slate-900 my-0.5'></div>
                        {type === 'file' &&
                            <>
                                <li>
                                    <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onMove}>
                                        <i className="ri-drag-move-2-line mr-3"></i>Move
                                    </button>
                                </li>
                                <li>
                                    <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onCopy}>
                                        <i className="ri-file-copy-2-line mr-3"></i>Copy
                                    </button>
                                </li>
                                <div className='w-full h-[1px] bg-slate-300 dark:bg-slate-900 my-0.5'></div>
                            </>
                        }
                        <li>
                            <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onDownload}>
                                <i className="ri-download-2-line mr-3"></i>Download
                            </button>
                        </li>
                        {type === 'file' ?
                            <li>
                                <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onShare}>
                                    <i className="ri-share-forward-2-fill mr-3"></i>Share
                                </button>
                            </li>
                            :
                            actionType ? actionType === 'cp' || actionType === 'mv' ?
                                <li>
                                    <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onPaste}>
                                        <i className="bi bi-clipboard mr-3"></i>Paste
                                    </button>
                                </li>
                                : null : null
                        }
                        <div className='w-full h-[1px] bg-slate-300 dark:bg-slate-900 my-0.5'></div>
                        <li>
                            <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onRename}>
                                <i className="bi bi-pencil-square mr-3"></i>Rename
                            </button>
                        </li>
                        <li>
                            <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onDelete}>
                                <i className="bi bi-trash mr-3"></i>Delete
                            </button>
                        </li>
                    </ul>
                    :
                    <ul>
                        <li>
                            <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onOpen}>
                                <i className="ri-book-open-line mr-3"></i>Open
                            </button>
                        </li>
                        <div className='w-full h-[1px] bg-slate-300 dark:bg-slate-900 my-0.5'></div>
                        <li>
                            <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onDownload}>
                                <i className="ri-download-2-line mr-3"></i>Download
                            </button>
                        </li>
                    </ul>
                }
            </div>
        </>
    );
}


export const ContextMenuCurrentDir: React.FC<Props> = ({ ofset, actionType, onUpload, onPaste }) => {
    return (
        <>
            <div className="origin-top-right z-10 absolute w-max bg-slate-700 border border-slate-600 text-white py-1.5 px-1 rounded shadow-lg overflow-hidden" style={ofset}>
                <ul>
                    <li>
                        <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onUpload}>
                            <i className="ri-upload-cloud-line mr-3"></i>Upload
                        </button>
                    </li>
                    <div className='w-full h-[1px] bg-slate-300 dark:bg-slate-900 my-0.5'></div>
                    {actionType ? actionType === 'cp' || actionType === 'mv' ?
                        <li>
                            <button className="font-medium hover:text-white hover:bg-orange-400 rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={onPaste}>
                                <i className="bi bi-clipboard mr-3"></i>Paste
                            </button>
                        </li>
                        : null : null
                    }
                </ul>
            </div>
        </>
    )
}

export default ContextMenu;