import React, { useCallback, useEffect, useState } from 'react';
import DataGridPagination from '../../components/DataGridPagination';
import DataGridToolbar from '../../components/DataGridToolbar';
import {
    DataGrid, GridColDef, GridRenderCellParams
} from '@mui/x-data-grid';
import { LinearProgress } from '@material-ui/core';
import Helper from '../../utils/Helper';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { toast } from 'react-toastify';
import { createNewCustomerService, CustomerServicePayload, deleteCustomerService, getAllCustomerService, updateCustomerService } from '../../api/cs-api';
import CustomerServiceForm from '../../components/modals/CSForm';
import moment from 'moment';


type Props = {};

const CustomerServiceManagement: React.FC<Props> = () => {
    const [pageSize, setPageSize] = useState(5);
    const [rows, setRows] = useState<any[]>([]);
    const [filteredRow, setFilteredRow] = useState<any[]>([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true)
    
    const [selectedData, setSelectedData] = useState<CustomerServicePayload | null>(null)
    const [actionType, setActionType] = useState(-5)

    const fectData = useCallback( async () => {
        setLoading(true)
        const res = await getAllCustomerService()
        console.log("All CS :", res)

        setLoading(false)
        if (res.status === 200) {
            setRows(res.data.data)
            setFilteredRow(res.data.data)
        } else {
            Helper.bulkResponseError(res)
        }
    }, [])

    useEffect(() => {
        fectData()
    }, [fectData])

    const handleCreateClick = () => {
        setSelectedData(null)
        setActionType(0)
    }

    const handleTableActionClick = (data: CustomerServicePayload, actionType: number) => {
        setSelectedData(data)
        setActionType(actionType)
    }


    const handleReceiveFormData = async (data: CustomerServicePayload) => {
        setLoading(true)

        let res
        if(actionType === 0){
            res = await createNewCustomerService(data)
        }else if(actionType === 1){
            res = await updateCustomerService(selectedData?.id!, data)
        }else if(actionType === 2){
            res = await deleteCustomerService(selectedData?.id!)
        }else{
            setLoading(false)
            return
        }

        console.log(res)

        setLoading(false)
        if(res.status === 200){
            toast.success(res.data?.message)
            resetForm()
            fectData()
        }else{
            Helper.bulkResponseError(res)
        }
    }

    const resetForm = () => {
        setActionType(-5)
        setSelectedData(null)
    }

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue)

        const newData = [...rows]
        if (searchValue) {
            const filtered = newData.filter((item: CustomerServicePayload) => {
                return (
                    item?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.whatsapp_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.gender?.toLowerCase().includes(searchValue.toLowerCase())
                )
            });

            setFilteredRow(filtered)
        } else {
            setFilteredRow(rows)
        }
    };

    const COLUMNS: GridColDef[] = [
        {
            field: "id", headerName: 'ID', hide: true, maxWidth: 30, headerClassName: 'bg-gray-100',
        },
        {
            field: 'name',
            headerName: 'CS Name',
            description:
                'The identification used by the person with access to the online service.',
            flex: 1,
            minWidth: 200, headerClassName: 'bg-gray-100',
        },
        {
            field: 'whatsapp_number', headerName: 'Whatsapp Number',
            flex: 1, minWidth: 160, headerClassName: 'bg-gray-100',
        },
        {
            field: 'gender', headerName: 'Gender',
            width: 120, headerClassName: 'bg-gray-100',
        },
        {
            field: 'status_active', headerName: "Status",
            type: 'boolean', width: 120, headerClassName: 'bg-gray-100',
            valueGetter: ({ value }) => parseInt(value)===0? false: true,
        },
        {
            field: 'created_at', headerName: "Created at",
            hide: true, type: 'dateTime', flex: 1, minWidth: 200, headerClassName: 'bg-gray-100',
            valueGetter: ({ value }) => moment(value).format('DD-MM-yyyy HH:mm:ss')
        },
        {
            field: 'updated_at', headerName: "Updated at",
            hide: true, type: 'dateTime', flex: 1, minWidth: 200, headerClassName: 'bg-gray-100',
            valueGetter: ({ value }) => moment(value).format('DD-MM-yyyy HH:mm:ss')
        },
        {
            field: 'actions',
            headerName: 'Action',
            headerClassName: 'bg-gray-100',
            type: 'actions',
            minWidth: 150,
            renderCell: (params: GridRenderCellParams) => (
                <div className='flex items-center space-x-2'>
                    <button className='btn-table-action bg-yellow-400' onClick={() => handleTableActionClick(params.row, 1)}>
                        <i className='bi bi-pencil-square flex items-center'></i>
                    </button>
                    <button className='btn-table-action bg-red-500' onClick={() => handleTableActionClick(params.row, 2)}>
                        <i className='bi bi-trash flex items-center'></i>
                    </button>
                </div>
            ),
        },
    ]

    return (
        <>
            <div className="flex flex-col items-center px-4 md:px-6 py-5">

                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5 flex items-center text-base md:text-xl'>
                        <i className="ri-customer-service-2-fill text-lg md:text-2xl mr-2 md:mr-3"></i>
                        <h1 className='font-bold'>Customer Services</h1>
                    </div>
                    <div>
                        <div>
                            <DataGrid
                                sx={{
                                    fontFamily: 'poppins'
                                }}

                                columns={COLUMNS}
                                rows={filteredRow}
                                components={{
                                    Toolbar: DataGridToolbar,
                                    Pagination: () => <DataGridPagination countData={rows.length} title='Customer Service' />,
                                    LoadingOverlay: LinearProgress,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        searchText: searchText,
                                        onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(""),
                                        pageSize: pageSize,
                                        onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => setPageSize(Number(event.target.value)),
                                        rowsPerPageOptions: [5, 10, 20, 50, 100],
                                        enableCreate: true,
                                        onCreate: () => handleCreateClick()
                                    }
                                }}

                                pageSize={pageSize}
                                autoHeight
                                loading={loading}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                actionType === 0 || actionType === 1?
                <CustomerServiceForm data={selectedData} onClose={resetForm} onSubmit={handleReceiveFormData} />
                :
                actionType === 2?
                <ConfirmModal message='Are you sure you want to delete this Customer Service?' onClose={resetForm} icon='bi bi-trash' onNext={() =>handleReceiveFormData(selectedData!)} />
                :
                null
            }
        </>
    );
}

export default CustomerServiceManagement;