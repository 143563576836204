import React from 'react'
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table'
import { IFolderAndFile } from '../api/document-api';


type Props = {
    columnTable: any[],
    dataTable: IFolderAndFile[],
    onDoubleClickRow?: any,
    onClickRow?: any,
    onRightClick?: any,
    onRowSelect?: any,
    selection?: false | true
};

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }: any, ref) => {
        const defaultRef = React.useRef<any>()
        const resolvedRef = ref || defaultRef as any

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} className='w-4 h-4 rounded transform duration-1000' />
            </>
        )
    }
)

const ReactTable = ({ columnTable, dataTable, onClickRow, onDoubleClickRow, onRightClick, onRowSelect, selection }: Props) => {
    // const data = useMemo(() => dataTable, [dataTable])

    const {
        getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canPreviousPage, canNextPage, pageOptions, gotoPage, pageCount, setPageSize, state, prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns: columnTable,
            data: dataTable,
            defaultColumn: {width: 10},
            //   initialState: { pageSize: 5 }
        },
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            selection && hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div className='ml-[1px]'>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    Cell: ({ row }: any) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        },
    )

    const { pageIndex, pageSize } = state

    React.useEffect(() => {
        if(onRowSelect){
            onRowSelect(selectedFlatRows);
        }
      }, [onRowSelect, selectedFlatRows]);

    // console.log('daas')

    return (
        <>
            {/* <div className="overflow-y-hidden w-[48vh] md:w-full relative">
                <table className="align-middle w-full min-w-max" {...getTableProps()}> */}
            <div className="overflow-x-auto md:overflow-x-visible w-[48vh] md:w-full">
                <table className={`align-middle w-full min-w-max overflow-x-visible`} {...getTableProps()}>
                    <thead className='bg-gray-200 dark:bg-slate-800 text-left'>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className={`py-3 px-2 text-left`} {...column.getHeaderProps({
                                        ...column.getSortByToggleProps(),
                                        style: { minWidth: column.minWidth, width: column.width }
                                    })}>
                                        {column.render('Header')}
                                        {/* Add a sort direction indicator */}
                                        <span>
                                            {column.isSorted ?
                                                column.isSortedDesc ?
                                                    <i className="ri-arrow-down-line"></i>
                                                    :
                                                    <i className="ri-arrow-up-line"></i>
                                                : ''
                                            }
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <tr className='border-b hover:bg-gray-200 dark:hover:bg-slate-700 cursor-pointer' {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td className='py-3 px-2' /* onClick={(e: any) => e.target.tagName !== 'INPUT' && onClickRow(e, row.original)} */ onDoubleClick={(e) => onDoubleClickRow(e, row.original)} onContextMenu={(e) => onRightClick(e, row.original)} {...cell.getCellProps({
                                            style: { minWidth: cell.column.minWidth, width: cell.column.width }
                                        })}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className='mt-5 mb-2 sm:mb-0 flex justify-between items-center'>
                <div className='hidden sm:flex items-center'>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </span>
                    <span className='mr-5'>
                        {' '} | Go to page :{' '}
                        <input
                            type='number'
                            defaultValue={pageIndex + 1}
                            onChange={e => {
                                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                gotoPage(pageNumber)
                            }}
                            className='w-20 outline-none border border-slate-500 dark:border-slate-200 rounded-2xl px-3 py-1'
                        />
                    </span>{' '}
                </div>

                <div className='flex items-center justify-between md:justify-end w-full md:w-max'>
                    <div className='flex items-center md:mr-5'>
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className={`${canPreviousPage ? 'text-slate-500 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-100' : 'text-slate-300 dark:text-slate-700 cursor-text'} font-bold mr-2`}>
                            {'<<'}
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage} className={`${canPreviousPage ? 'text-slate-500 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-100' : 'text-slate-300 dark:text-slate-700 cursor-text'} mr-2`}>
                            Previous
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage} className={`${canNextPage ? 'text-slate-500 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-100' : 'text-slate-300 dark:text-slate-700 cursor-text'} mr-2`}>
                            Next
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className={`${canNextPage ? 'text-slate-500 hover:text-slate-800 dark:text-slate-300 dark:hover:text-slate-100' : 'text-slate-300 dark:text-slate-700 cursor-text'} font-bold`}>
                            {'>>'}
                        </button>
                    </div>
                    <select className='outline-none px-2 py-1 border border-slate-500 dark:border-slate-200 rounded-2xl cursor-pointer' value={pageSize}
                        onChange={e => setPageSize(Number(e.target.value))}>
                        {[10, 20, 50, 100, 200, 500].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

        </>
    )
}

export default ReactTable