import React, { useCallback, useEffect, useState } from 'react';
import DataGridPagination from '../../components/DataGridPagination';
import DataGridToolbar from '../../components/DataGridToolbar';
import {
    DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams
} from '@mui/x-data-grid';
import { LinearProgress } from '@material-ui/core';
import { approveUser, changeUserPassword, createNewUser, deleteUser, getAllUser, getRoleUser, updateUser, UserPayload } from '../../api/user-api';
import Helper from '../../utils/Helper';
import UserForm from '../../components/modals/UserForm';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { toast } from 'react-toastify';
import ChangePasswordForm from '../../components/modals/ChangePasswordForm';
import moment from 'moment';

// const DUMMY_LIST_FOLDER = [
//     {name: 'documents', path: 'uploads/documents'},
//     {name: 'learn', path: 'uploads/documents/learn'},
//     {name: 'projects', path: 'uploads/documents/projects'},
//     {name: 'backup', path: 'uploads/documents/backup'},
// ]


type Props = {};

const UserManagement: React.FC<Props> = () => {
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState<any[]>([]);
    const [filteredRow, setFilteredRow] = useState<any[]>([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line
    const [roleList, setRoleList] = useState<any>([])
    
    const [selectedData, setSelectedData] = useState<UserPayload | null>(null)
    const [actionType, setActionType] = useState(-5)

    const fectData = useCallback( async () => {
        setLoading(true)
        const res = await getAllUser()
        console.log("All User :", res)

        setLoading(false)
        if (res.status === 200) {
            setRows(res.data.data)
            setFilteredRow(res.data.data)
        } else {
            Helper.bulkResponseError(res)
        }
    }, [])

    useEffect(() => {
        fectData()
    }, [fectData])
    
    useEffect(() => {
        async function fetchRoles() {
            const res = await getRoleUser()
            if (res.status === 200) {
                if(res.data.success){
                    localStorage.setItem('roleList', JSON.stringify(res.data.data))
                    setRoleList(res.data.data)
                }
            }
        }

        fetchRoles()
    }, [])

    
    const handleCreateClick = () => {
        setSelectedData(null)
        setActionType(0)
    }

    const handleTableActionClick = (data: UserPayload, actionType: number) => {
        setSelectedData(data)
        setActionType(actionType)
    }


    const handleReceiveFormData = async (data: UserPayload) => {
        setLoading(true)

        let res
        if(actionType === 0){
            res = await createNewUser(data)
        }else if(actionType === 1){
            res = await updateUser(selectedData?.id!, data)
        }else if(actionType === 2){
            res = await deleteUser(selectedData?.id!)
        }else if(actionType === 3){
            res = await changeUserPassword(selectedData?.id!, data)
        }else if(actionType === 4){
            res = await approveUser(selectedData?.id!, selectedData?.user_approved_at? false: true)
        }else{
            setLoading(false)
            return
        }

        console.log('Action User :', res)

        setLoading(false)
        if(res.status === 200){
            toast.success(res.data?.message)
            fectData()
            resetForm()
        }else{
            Helper.bulkResponseError(res)
        }
    }

    const resetForm = () => {
        setActionType(-5)
        setSelectedData(null)
    }

    const requestSearch = (searchValue: string) => {
        setSearchText(searchValue)

        const newData = [...rows]
        if (searchValue) {
            const filtered = newData.filter((item: UserPayload) => {
                return (
                    item?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.whatsapp_number?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item?.user_folder?.toLowerCase().includes(searchValue.toLowerCase())
                )
            });

            setFilteredRow(filtered)
        } else {
            setFilteredRow(rows)
        }
    };

    const COLUMNS: GridColDef[] = [
        {
            field: "id", headerName: 'ID', hide: true, maxWidth: 30, headerClassName: 'bg-gray-100',
        },
        {
            field: 'name',
            headerName: 'Fullname',
            description:
                'The identification used by the person with access to the online service.',
            flex: 1,
            minWidth: 190, headerClassName: 'bg-gray-100',
        },
        {
            field: 'username', headerName: 'Username',
            minWidth: 140, headerClassName: 'bg-gray-100',
        },
        {
            field: 'user_role', headerName: 'Role',
            minWidth: 130, headerClassName: 'bg-gray-100',
            valueGetter: (params: GridValueGetterParams) => {
                return params.row.user_role[0]?.name
            }
        },
        {
            field: 'email', headerName: 'E-mail',
            minWidth: 240, flex: 1, headerClassName: 'bg-gray-100',
        },
        {
            field: 'whatsapp_number', headerName: 'Whatsapp Number',
            minWidth: 160, hide: true, headerClassName: 'bg-gray-100',
        },
        {
            field: 'user_folder', headerName: "User's Folder",
            flex: 1, minWidth: 200, headerClassName: 'bg-gray-100',
        },
        {
            field: 'user_approved_at', headerName: "Approved at",
            hide: true, type: 'dateTime', minWidth: 180, headerClassName: 'bg-gray-100',
            // valueGetter: ({ value }) => value && new Date(value),
            valueGetter: ({ value }) => value && moment(value).format('DD-MM-yyyy HH:mm:ss')
        },
        {
            field: 'user_approved_by', headerName: "Approved By",
            hide: true, minWidth: 175, headerClassName: 'bg-gray-100',
        },
        {
            field: 'created_at', headerName: "Registration at",
            type: 'dateTime', minWidth: 180, headerClassName: 'bg-gray-100',
            valueGetter: ({ value }) => value && moment(value).format('DD-MM-yyyy HH:mm:ss')
        },
        {
            field: 'updated_at', headerName: "Updated at",
            hide: true, type: 'dateTime', minWidth: 180, headerClassName: 'bg-gray-100',
            valueGetter: ({ value }) => value && moment(value).format('DD-MM-yyyy HH:mm:ss')
        },
        {
            field: 'actions',
            headerName: 'Action',
            headerClassName: 'bg-gray-100',
            type: 'actions',
            minWidth: 175,
            renderCell: (params: GridRenderCellParams) => (
                <div className='flex items-center space-x-2'>
                    <button className={`btn-table-action ${params.row.user_approved_at? 'bg-green-500':''}`} onClick={()=>handleTableActionClick(params.row, 4)}>
                        <i className={params.row.user_approved_at? 'bi bi-check-lg flex items-center' : "bi bi-question-lg flex items-center"}></i>
                    </button>
                    <button className='btn-table-action bg-blue-500' onClick={()=>handleTableActionClick(params.row, 3)}>
                        <i className="ri-lock-password-fill"></i>
                    </button>
                    <button className='btn-table-action bg-yellow-400' onClick={() => handleTableActionClick(params.row, 1)}>
                        <i className='bi bi-pencil-square flex items-center'></i>
                    </button>
                    <button className='btn-table-action bg-red-500' onClick={() => handleTableActionClick(params.row, 2)}>
                        <i className='bi bi-trash flex items-center'></i>
                    </button>
                </div>
            ),
        },
    ]

    return (
        <>
            <div className="flex flex-col items-center px-4 md:px-6 py-5">

                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className='mb-5 flex items-center text-base md:text-xl'>
                        <i className="bi bi-people-fill text-lg md:text-2xl mr-2 md:mr-3"></i>
                        <h1 className='font-bold'>Users</h1>
                    </div>
                    <div>
                            <DataGrid
                                sx={{
                                    fontFamily: 'poppins'
                                }}

                                columns={COLUMNS}
                                rows={filteredRow}
                                components={{
                                    Toolbar: DataGridToolbar,
                                    Pagination: () => <DataGridPagination countData={rows.length} title='User' />,
                                    LoadingOverlay: LinearProgress,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        searchText: searchText,
                                        onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                                        clearSearch: () => requestSearch(""),
                                        pageSize: pageSize,
                                        onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => setPageSize(Number(event.target.value)),
                                        rowsPerPageOptions: [5, 10, 20, 50, 100],
                                        enableCreate: true,
                                        onCreate: () => handleCreateClick()
                                    }
                                }}

                                pageSize={pageSize}
                                autoHeight
                                loading={loading}
                                disableSelectionOnClick
                            />
                    </div>
                </div>
            </div>

            {
                actionType === 0 || actionType === 1?
                <UserForm data={selectedData} roleList={roleList} onClose={resetForm} onSubmit={handleReceiveFormData} />
                :
                actionType === 2?
                <ConfirmModal message='Are you sure you want to delete this user?' onClose={resetForm} icon='bi bi-trash' onNext={() =>handleReceiveFormData(selectedData!)} />
                :
                actionType === 3?
                <ChangePasswordForm data={selectedData} onClose={resetForm} onSubmit={handleReceiveFormData} />
                :
                actionType === 4?
                <ConfirmModal message={selectedData?.user_approved_at? 'Are you sure to disapprove this user?' : 'Are you sure to approve this user?'} onClose={resetForm} icon={selectedData?.user_approved_at? 'bi bi-person-x': 'bi bi-person-check'} onNext={() =>handleReceiveFormData(selectedData!)} />
                :
                null
            }
        </>
    );
}

export default UserManagement;