import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AxiosResponse } from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import FileSaver from 'file-saver';
import { Action, Fab } from 'react-tiny-fab';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';

import Loader from '../components/Loader';
import ConfirmModal from '../components/modals/ConfirmModal';
import { BG_AVATAR } from '../components/ProfileMenu';
import Helper from '../utils/Helper';
import logoDark from './../assets/img/logo-dark.png'
import logoLight from './../assets/img/logo-light.png'

import DocumentAPI, { IFolderAndFile } from '../api/document-api';
import { baseURLImage, logout } from '../api/common-api';
import ContextMenu from '../components/ContextMenu';
import RenameModal from '../components/modals/RenameModal';
import Folders from '../containers/Folders';
import Files from '../containers/Files';
import useStartAnimation from '../hooks/useStartAnimation';
import ContactCSModal from '../components/modals/ContactCSModal';
import InformationModal from '../components/modals/InformationModal';
import { useSetRecoilState } from 'recoil';
import { configurationState } from '../atoms/recoilState';
import { IConfig } from '../hooks/useConfig';
import ShareModal from '../components/modals/ShareModal';
import { ThemeContext } from '../contexts/ThemeContext';
import AvatarModal from '../components/modals/AvatarModal';
import ViewFileModal from '../components/modals/ViewFileModal';
import moment from 'moment';

type Props = {};

const HomeApps: React.FC<Props> = () => {
    const [bgAvatar, setBgAvatar] = useState(BG_AVATAR[0])

    const [fullLoader, showFullLoader] = useState(false)
    const [titleLoader, setTitleLoader] = useState('Loading file directory...')
    const [modalLogout, showModalLogout] = useState(false)
    const [newDirName, setNewDirName] = useState('')

    const [folders, setFolders] = useState<IFolderAndFile[]>([])
    const [files, setFiles] = useState<IFolderAndFile[]>([])

    const [type, setType] = useState<'file' | 'folder'>('folder')
    const [actionType, setActionType] = useState<'cp' | 'mv' | 'rn' | 'rm' | null>(null)
    const [selectedItem, setSelectedItem] = useState<IFolderAndFile | null>(null)
    const [clipboardItem, setClipboardItem] = useState<IFolderAndFile | null>(null)
    const [groupClpboard, setGroupClipboard] = useState<IFolderAndFile[] | null>(null)

    const [fileToShare, setFileToShare] = useState<string[]>([''])

    const [menuOfset, setMenuOffset] = useState<any>({ left: 0, top: 0 })
    const [contextMenu, showContextMenu] = useState(false)
    const [rename, showRename] = useState(false)
    const [share, showShare] = useState(false)
    const [confirmDelete, showConfirmDelete] = useState(false)

    const [navMobile, showNavMobile] = useState(false)
    const [onDragFile, setOnDragFile] = useState(false)

    const [csModal, showCSModal] = useState(false)
    const [viewFile, setViewFile] = useState<any>(null)

    const [count, setCount] = useState(0);
    const [intervalId, setIntervalId] = useState<any>(null);
    const [modalAvatar, showModalAvatar] = useState(false)

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const token = localStorage.getItem('authToken')
    useEffect(() => {
        if (!token) navigate('/auth', { replace: true })
    }, [navigate, token])

    const { theme, setTheme, userStorage, setUserStorage, openAnnounce, setOpenAnnounce } = React.useContext(ThemeContext);

    // const user = localStorage.getItem('dataUser') ? JSON.parse(localStorage.getItem('dataUser') || '') : {}
    const role = userStorage ? parseInt(userStorage.role) : 99

    const setRecoilConfigState = useSetRecoilState(configurationState)
    const config: IConfig[] = useMemo(() => localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config') || '') : [], [])


    const dropzoneRef = useRef<any | null>(null);

    useEffect(() => {
        setBgAvatar(BG_AVATAR[Math.floor(Math.random() * BG_AVATAR.length)])
    }, [])

    useEffect(() => {
        setRecoilConfigState(config)
    }, [setRecoilConfigState, config])


    const handleLogout = async () => {
        showFullLoader(true)
        setTitleLoader('Please wait...')

        await logout()

        setUserStorage(null)
        setOpenAnnounce('open')
        localStorage.clear()
        navigate('/auth', { replace: true })
    }


    const fetchFolderFile = useCallback(async () => {
        showFullLoader(true)
        setTitleLoader('Loading file directory...')

        const path = pathname.replace('/root', 'uploads')
        const res = await DocumentAPI.showFolderAndFile(path)

        console.log("FolderFile :", res)
        showFullLoader(false)
        if (res.status === 200) {
            if (res.data.success) {
                const sortFolders = [...res.data.data.folders].sort((a, b) => a['name']!.localeCompare(b['name']!))
                setFolders(sortFolders)
                // setFolders(res.data.data.folders)
                const sortFiles = [...res.data.data.files].sort((b, a) => moment(a['last_modified'], moment(a['last_modified']).creationData().format).valueOf() - moment(b['last_modified'], moment(a['last_modified']).creationData().format).valueOf())
                setFiles(sortFiles)
                // setFiles(res.data.data.files)
            } else {
                Helper.bulkResponseError(res)
            }
        } else {
            Helper.bulkResponseError(res)
        }
    }, [pathname])

    useEffect(() => {
        fetchFolderFile()
    }, [fetchFolderFile])


    const handleCreateNewFolder = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        showFullLoader(true)
        setNewDirName('')
        setTitleLoader('Creating new folder...')

        const newPath = pathname.replace('/root', 'uploads')
        const res = await DocumentAPI.createFolder(newPath + '/' + newDirName.split(' ').join('_'))

        handleResponseApi(res)
    }


    //#region Conntext Menu
    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: any) => {
            if (!contextMenu) return;

            if (count > 25) {
                clearInterval(intervalId)
                setIntervalId(0);
                setCount(0)
                showContextMenu(false)
            }
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    useEffect(() => {
        if (count > 26) {
            setIntervalId(0)
            clearInterval(intervalId)
        }
    }, [count, intervalId])


    const handleRightClickItem = (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLButtonElement, MouseEvent>, data: IFolderAndFile, type: 'file' | 'folder') => {
        setCount(0)
        const newIntervalId = setInterval(() => {
            setCount(prevCount => prevCount + 1);
        }, 1);
        setIntervalId(newIntervalId);

        event.preventDefault()
        setSelectedItem(data)
        setType(type)
        setMenuOffset({ top: event.clientY, left: event.clientX });
        showContextMenu(true)
        // window.location.href = baseURL.replace('/api', '') + '/' + data.path
    }

    const handleSingleAction = (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLButtonElement, MouseEvent>, data: IFolderAndFile, type: 'file' | 'folder') => {
        // if (intervalId) {
        //     clearInterval(intervalId);
        //     setIntervalId(0);
        //     return;
        // }

        setCount(0)
        const newIntervalId = setInterval(() => {
            setCount(prevCount => prevCount + 1);
        }, 1);
        setIntervalId(newIntervalId);

        event.preventDefault()
        setSelectedItem(data)
        setType(type)
        setMenuOffset({ top: event.clientY, left: event.clientX - 180 });
        showContextMenu(true)
        // window.location.href = baseURL.replace('/api', '') + '/' + data.path
    }
    //#endregion


    const handleDoubleClickItem = async (event: React.MouseEvent<HTMLDivElement | HTMLSpanElement | HTMLButtonElement, MouseEvent>, data: IFolderAndFile, type: 'file' | 'folder') => {
        event.preventDefault()
        showContextMenu(false)
        if (type === 'folder') {
            navigate(data.path?.replace('uploads', '/root')!)
        } else {
            showFullLoader(true)
            const res = await DocumentAPI.downloadFile([data?.path!])
            console.log("Opening File :", res)

            // window.open(res.data.data, '_blank');
            // showFullLoader(false)

            const openExt = ['png', 'jpg', 'jpeg', 'gif', 'pdf']
            const extension = data.extension;
            if (extension) {
                if (openExt.includes(extension)) {
                    // showFullLoader(true)
                    // setTitleLoader('Opening file...')
                    // var w = window.open("") as any;

                    // if (extension === 'pdf') {
                    //     const src = res.data.data
                    //     w.document.write("<iframe width='100%' height='100%' src='" + src + "'></iframe>")
                    // } else {
                    //     const src = res.data.data
                    //     w.document.write("<img width='100%' src='" + src + "'></img>")
                    // }
                    // w.document.close()
                    setViewFile(res.data.data)
                } else {
                    window.open(res.data.data, '_blank');
                }
            }
        }
        showFullLoader(false)
    }

    const handlePaste = async (currentDir: boolean) => {
        showFullLoader(true)

        let res
        let oldPath: string[] = []
        let newPath: string[] = []

        if (currentDir) {
            if (groupClpboard) {
                oldPath = groupClpboard?.map(item => item.path) as string[]

                groupClpboard?.forEach(item => {
                    newPath.push(pathname.replace('/root', 'uploads') + '/' + item.name + '.' + item.extension)
                })
            } else {
                oldPath = [clipboardItem?.path!]
                newPath = [pathname.replace('/root', 'uploads') + '/' + clipboardItem?.name + '.' + clipboardItem?.extension]
            }
        } else {
            if (groupClpboard) {
                oldPath = groupClpboard?.map(item => item.path) as string[]

                groupClpboard?.forEach(item => {
                    newPath.push(selectedItem?.path + '/' + item.name + '.' + item.extension)
                })
            } else {
                oldPath = [clipboardItem?.path!]
                newPath = [selectedItem?.path! + '/' + clipboardItem?.name + '.' + clipboardItem?.extension]
            }
        }

        if (actionType === "mv") {
            setTitleLoader('Moving file...')
            res = await DocumentAPI.moveFile(newPath!, oldPath)
        } else if (actionType === "cp") {
            setTitleLoader('Copying file...')
            res = await DocumentAPI.copyFile(newPath!, oldPath)
        } else {
            toast.warning('Oops something error!')
            reset()
            return
        }

        handleResponseApi(res)
    }


    const handleRename = async (data: IFolderAndFile) => {
        showFullLoader(true)
        setTitleLoader('Renaming folder/file...')
        const oldname = type === 'folder' ? selectedItem?.name : selectedItem?.name + '.' + selectedItem?.extension

        const ext = selectedItem?.extension
        const newFilename: string | undefined = type === 'folder' ? data.name!.replaceAll(' ', '_').replaceAll('.', '_') : data.name?.replaceAll(' ', '_').replaceAll('.', '_') + '.' + ext

        const res = await DocumentAPI.rename(selectedItem?.path?.replace(oldname!, newFilename!)!, selectedItem?.path!)
        handleResponseApi(res)
    }

    const handleDelete = async (path: string[]) => {
        showFullLoader(true)
        setTitleLoader('Removing folder/file...')

        let res
        if (type === 'folder') {
            res = await DocumentAPI.deleteFolder(selectedItem?.path!)
        } else {
            res = await DocumentAPI.deleteFile(path)
        }

        handleResponseApi(res)
    }

    const handleResponseApi = (res: AxiosResponse) => {
        console.log("Action :", res)
        showFullLoader(false)

        if (res.status === 200) {
            if (res.data.success) {
                toast.success(res.data.message)
                fetchFolderFile()
                reset()
            }
        } else {
            Helper.bulkResponseError(res)
        }
    }

    const handleDownload = async (path: string[]) => {
        showFullLoader(true)
        setTitleLoader('Downloading file...')

        const res = await DocumentAPI.downloadFile(path)
        console.log(res)

        if (res.data) {
            if (path.length === 1) {
                const openExt = ['png', 'jpg', 'jpeg', 'gif', 'pdf']
                const extension = path[0].split('.')[1]

                if (openExt.includes(extension)) {
                    FileSaver.saveAs(res.data.data, path[0])
                } else {
                    window.open(res.data.data, '_blank');
                }
            } else {
                window.open(res.data.data, '_blank');
            }
        } else {
            Helper.bulkResponseError(res)
        }
        reset()
        showFullLoader(false)
    }

    const openDialog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };


    const onDrop = useCallback(async (acceptedFiles: any[]) => {
        setOnDragFile(false)
        // console.log(acceptedFiles)
        // console.log(pathname)

        if (acceptedFiles.length > 0) {
            showFullLoader(true)
            setTitleLoader('Uploading file...')

            const formData = new FormData()

            const files = acceptedFiles
            if (files) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('file', files[i])
                }
            }
            formData.append('path_folder', pathname.replace('/root', 'uploads'))
            formData.append('total_file', files.length.toString());

            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }

            const res = await DocumentAPI.uploadFile(formData)
            handleResponseApi(res)
        }
        // eslint-disable-next-line
    }, [pathname])

    const reset = () => {
        setSelectedItem(null)
        setClipboardItem(null)
        setGroupClipboard(null)
        setFileToShare([''])
        setActionType(null)
        showRename(false)
        showShare(false)
        showConfirmDelete(false)
        showContextMenu(false)
    }

    // const handleUploadTest = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setOnDragFile(false)

    //     if (e.target.files!.length > 0) {
    //         showFullLoader(true)
    //         setTitleLoader('Uploading file...')

    //         const formData = new FormData()

    //         const files = e.target.files
    //         if (files) {
    //             for (let i = 0; i < files.length; i++) {
    //                 formData.append('file', files[i])
    //             }
    //         }
    //         formData.append('path_folder', pathname.replace('/root', 'uploads'))
    //         // formData.append('total_file', files.length.toString());

    //         const res = await DocumentAPI.uploadFile(formData)
    //         handleResponseApi(res)
    //     }
    // }

    return (
        <>
            {/* <input type='file' multiple onChange={handleUploadTest} className='text-slate-900 dark:text-white' /> */}
            <Dropzone ref={dropzoneRef} onDrop={onDrop} onDragEnter={() => setOnDragFile(true)} onDragLeave={() => console.log('on drag leave')} noClick noKeyboard>
                {({ getRootProps, getInputProps, acceptedFiles }) => {
                    return (
                        <div {...getRootProps({ className: 'dropzone' })} className='overflow-auto'>
                            <div className='flex flex-1 flex-col h-screen overflow-x-hidden overflow-y-auto cursor-default transition duration-500 bg-soft dark:bg-slate-900 text-slate-700 dark:text-slate-300'>

                                <nav className="sticky top-0 z-10 w-full backdrop-blur flex-none transition-colors duration-500 bg-soft dark:bg-slate-900/75 dark:supports-backdrop-blur:bg-white/95 border-b border-slate-300/75 dark:border-slate-800 py-5">
                                    {/* NAVBAR */}
                                    <div className='lg:w-[1366px] mx-auto flex justify-between items-center px-4 md:px-20 dark:text-slate-100'>
                                        <h1 className='cursor-pointer text-base md:text-lg font-bold' onClick={() => window.location.reload()}>{Helper.getConfigByCode(config, "C000")}</h1>
                                        <div className='hidden lg:flex items-center space-x-3 md:space-x-8'>
                                            <div className='flex items-center space-x-2 font-semibold hover:text-orange-400 cursor-pointer' onClick={() => showModalAvatar(true)}>
                                                <LazyLoadImage effect='blur' alt='' src={baseURLImage + userStorage?.avatar || `https://ui-avatars.com/api/?name=${userStorage?.name || userStorage?.username}&background=${bgAvatar}&color=fff`} className='w-5 h-5 rounded-full' />
                                                <h5 className='hidden md:block'>{userStorage?.name || userStorage?.username || 'username'}</h5>
                                            </div>
                                            {/* {parseInt(userStorage?.role) === 1 &&
                                                <Link className='flex items-center space-x-1 font-semibold hover:text-orange-400' to='/admin'>
                                                    <i className="ri-admin-fill"></i>
                                                    <h5 className='hidden md:block'>Administration</h5>
                                                </Link>
                                            } */}
                                            <div className='pl-6 border-l border-slate-300 dark:border-slate-800 space-x-5 dark:text-slate-300'>
                                                {parseInt(userStorage?.role) === 1 &&
                                                    <Link to='/admin'>
                                                        <i className="ri-user-settings-line text-xl cursor-pointer hover:text-slate-400 hover:dark:text-white"></i>
                                                    </Link>
                                                }
                                                <i className="ri-logout-circle-r-line text-xl cursor-pointer hover:text-slate-400 hover:dark:text-white" onClick={() => showModalLogout(true)}></i>
                                            </div>
                                        </div>
                                        <div className='lg:hidden -mr-1 flex'>
                                            <button onClick={() => showNavMobile(!navMobile)}
                                                className='bg-slate-200 dark:bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-orange-500 hover:text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-200 focus:dark:ring-offset-gray-800 focus:ring-orange-500'
                                            >
                                                {navMobile ?
                                                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" >
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                    :
                                                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                                    </svg>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    {navMobile &&
                                        <NavMobile onClick={() => showNavMobile(false)} onLogout={() => showModalLogout(true)} onCLickAvatar={() => showModalAvatar(true)} user={userStorage} bgAvatar={bgAvatar} />
                                    }
                                </nav>

                                {/* CONTENT */}
                                <div className='lg:w-[1366px] mx-auto px-4 md:px-20 mb-10 mt-3'>

                                    <div className='flex justify-center -mb-5'>
                                        <LazyLoadImage effect='blur' alt='' src={theme === 'dark' ? logoLight : logoDark} className='z-0 w-80 sm:w-[26rem]' />
                                    </div>


                                    {/* BreadCrumps */}
                                    <nav className="flex mt-10 md:mt-8" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                            <li className="inline-flex items-center">
                                                <Link to='/root' className="inline-flex items-center text-sm font-medium text-slate-700 hover:text-slate-400 dark:text-gray-400 dark:hover:text-white">
                                                    <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                                    Home
                                                </Link>
                                            </li>
                                            {Helper.generateBreatCrumbItem(pathname).length > 0 &&
                                                Helper.generateBreatCrumbItem(pathname).map((data, key) =>
                                                    <li key={key}>
                                                        <div className="flex items-center">
                                                            <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                            <Link to={'/root' + data.path} className="ml-1 text-sm font-medium text-slate-700 hover:text-slate-400 md:ml-2 dark:text-gray-400 dark:hover:text-white">{data.name}</Link>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        </ol>
                                    </nav>

                                    {/* New Dir or FIle */}
                                    {role === 1 &&
                                        <div className='flex flex-col lg:flex-row mt-6 md:mt-10 space-y-4 lg:space-y-0 lg:space-x-10'>
                                            <div className='flex items-center w-full'>
                                                <i className="ri-file-add-fill text-xl"></i>
                                                <div className='w-full border-b border-slate-300 dark:border-slate-800 px-4 py-2'>
                                                    <label htmlFor='files' className='cursor-pointer min-w-full pr-[9rem] lg:pr-[24rem]'>Select file(s)</label>
                                                </div>
                                                <button onClick={openDialog} className='cursor-pointer bg-orange-400 px-5 py-1 text-white rounded hover:bg-orange-500 focus:ring-4 focus:ring-orange-400'>
                                                    {/* <label htmlFor='files' className='cursor-pointer bg-orange-400 px-5 pt-3 py-1 text-white rounded hover:bg-orange-500 focus:ring-4 focus:ring-orange-400'> */}
                                                    <i className="ri-upload-2-fill text-xl"></i>
                                                    {/* </label> */}
                                                </button>
                                            </div>
                                            <input id='files' {...getInputProps()} className='hidden' />
                                            <div className='flex items-center w-full'>
                                                <i className="ri-folder-add-fill text-xl"></i>
                                                <input type='text' value={newDirName} onChange={(e) => setNewDirName(e.target.value)} placeholder='New Directory' className='w-full outline-none bg-transparent border-b border-slate-300 dark:border-slate-800 focus:border-orange-400 px-4 py-2' />
                                                <button className={`bg-orange-400 px-5 py-1 text-white rounded hover:bg-orange-500 focus:ring-4 focus:ring-orange-400 ${!newDirName && 'cursor-not-allowed'}`} disabled={!newDirName} onClick={handleCreateNewFolder}>
                                                    <i className="ri-add-fill text-xl"></i>
                                                </button>
                                            </div>
                                        </div>
                                    }

                                    {/* FODLER */}
                                    <div className='mt-10'>
                                        <Folders
                                            data={folders}
                                            onDoubleClick={handleDoubleClickItem}
                                            onContextMenu={handleRightClickItem}
                                            onSingleAction={handleSingleAction}
                                        />
                                    </div>

                                    {/* FILES */}
                                    <div className='mt-10'>
                                        <Files
                                            data={files}
                                            onDoubleClick={handleDoubleClickItem}
                                            onSingleAction={handleSingleAction}
                                            onContextMenu={handleRightClickItem}
                                            showPaste={clipboardItem !== null ? clipboardItem?.path?.split('/').slice(0, clipboardItem?.path?.split('/').length - 1).join('/') !== pathname.replace('/root', 'uploads') : groupClpboard !== null && groupClpboard[0]?.path?.split('/').slice(0, groupClpboard[0]?.path?.split('/').length - 1).join('/') !== pathname.replace('/root', 'uploads')}
                                            onPaste={() => handlePaste(true)}
                                            onRenameSelection={(data) => { setSelectedItem(data); setType('file'); showRename(true) }}
                                            onGroupCopy={(data) => { setGroupClipboard(data); setClipboardItem(null); setActionType('cp'); toast.info('Clipboard ' + data.length + ' item') }}
                                            onGroupMove={(data) => { setGroupClipboard(data); setClipboardItem(null); setActionType('mv'); toast.info('Clipboard ' + data.length + ' item') }}
                                            onGroupDownload={(data) => handleDownload(data.map(item => item.path) as string[])}
                                            onGroupShare={(data) => { setFileToShare(data.map(item => item.path) as string[]); showShare(true) }}
                                            onGroupDelete={(data) => { setFileToShare(data.map(item => item.path) as string[]); showConfirmDelete(true); setType('file') }}
                                        />
                                    </div>

                                    <div>
                                        {/* <div className='w-full mt-8'>
                                        <div>
                                            <div>
                                                <DataGrid
                                                    sx={{
                                                        fontFamily: 'poppins',
                                                        color: 'rgba(255,255,255,0.85)',
                                                        border: `1px solid #1e293b`,
                                                        borderRadius: '10',
                                                        '& .MuiDataGrid-columnsContainer': {
                                                            backgroundColor: '#1d1d1d',
                                                        },
                                                        '& .MuiDataGrid-iconSeparator': {
                                                            display: 'none',
                                                        },
                                                        '& .MuiDataGrid-columnHeader': {
                                                            borderColor: `#1e293b`,
                                                        },
                                                        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                                                            borderBottom: `1px solid #1e293b`,
                                                        },
                                                        '& .MuiDataGrid-cell': {
                                                            color: 'rgba(255,255,255,0.85)',
                                                            WebkitFontSmoothing: 'auto',
                                                            letterSpacing: 'normal',
                                                            '& .MuiDataGrid-columnsContainer': {
                                                                backgroundColor: '#1d1d1d',
                                                            },
                                                            '& .MuiDataGrid-iconSeparator': {
                                                                display: 'none',
                                                            },
                                                            '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                                                                borderBottom: `1px solid #1e293b`,
                                                            },
                                                            '& .MuiDataGrid-cell': {
                                                                color: 'rgba(255,255,255,0.65)',
                                                            },
                                                        },
                                                        '& .MuiIconButton-root': {
                                                            color: 'white'
                                                        }
                                                    }}

                                                    columns={COLUMNS}
                                                    rows={filteredRow}
                                                    components={{
                                                        // Toolbar: CustomToolbar,
                                                        Toolbar: DataGridToolbar,
                                                        Pagination: DataGridPagination,
                                                        LoadingOverlay: LinearProgress,
                                                    }}
                                                    componentsProps={{
                                                        toolbar: {
                                                            searchText: searchText,
                                                            onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
                                                            clearSearch: () => requestSearch(""),
                                                            pageSize: pageSize,
                                                            onPageSizeChange: (event: React.ChangeEvent<HTMLInputElement>) => setPageSize(Number(event.target.value)),
                                                            rowsPerPageOptions: [5, 10, 20, 50, 100]
                                                        }
                                                    }}
                                                    // getRowClassName={() => 'font-poppins'}

                                                    pageSize={pageSize}
                                                    // pagination
                                                    // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                                    // rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                                    autoHeight
                                                    loading={loading}
                                                // disableSelectionOnClick
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>

                                </div>
                            </div>
                            {onDragFile &&
                                <div className='modal-form'>
                                    <div className='modal-form-outside' onClick={() => setOnDragFile(false)} />
                                    <i className='ri-upload-cloud-fill text-white text-8xl lg:text-9xl z-50'></i>
                                </div>
                            }
                        </div>
                    )
                }}

            </Dropzone>

            {/* FLOATING ACTION BUTTON */}
            <Fab
                mainButtonStyles={{ backgroundColor: '#fb923c' }}
                icon={<i className='bi bi-question-circle text-3xl'></i>}
                // event={window.screen.width > 500? 'hover': 'click'}
                event={'hover'}
                text="Help"
            >
                <Action
                    text="Information"
                    style={{ backgroundColor: '#f97316' }}
                    onClick={() => setOpenAnnounce('open')}
                >
                    <i className="bi bi-info-circle text-2xl" />
                </Action>
                <Action
                    text="Customer Service"
                    style={{ backgroundColor: '#f97316' }}
                    onClick={() => showCSModal(true)}
                >
                    <i className="ri-customer-service-2-line text-2xl" />
                </Action>
                <Action
                    text={theme === 'dark' ? "Switch to Light Mode" : 'Switch to Dark Mode'}
                    style={{ backgroundColor: '#f97316' }}
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                >
                    {theme === 'dark' ?
                        <i className="ri-sun-line text-2xl" /> : <i className="ri-moon-fill text-2xl" />
                    }
                </Action>
            </Fab>

            {contextMenu &&
                <ContextMenu ofset={menuOfset} type={type} actionType={actionType}
                    onOpen={(e) => handleDoubleClickItem(e, selectedItem!, type)}
                    onMove={() => { setClipboardItem(selectedItem); setGroupClipboard(null); setActionType('mv') }}
                    onCopy={() => { setClipboardItem(selectedItem); setGroupClipboard(null); setActionType('cp') }}
                    onPaste={() => { handlePaste(false); showContextMenu(false) }}
                    onDownload={() => handleDownload([selectedItem?.path!])}
                    onShare={() => { setFileToShare([selectedItem?.path!]); showShare(true) }}
                    onDelete={() => { setFileToShare([selectedItem?.path!]); showConfirmDelete(true) }}
                    onRename={() => showRename(true)}
                />
            }
            {rename && <RenameModal data={selectedItem} type={type} onClose={reset} onSubmit={handleRename} />}
            {share && <ShareModal pathFile={fileToShare} onClose={reset} />}
            {confirmDelete && <ConfirmModal message='Are you sure to this item' icon='bi bi-trash' onClose={reset} onNext={() => handleDelete(fileToShare)} />}

            {modalLogout && <ConfirmModal message='Are you sure to logout?' onClose={() => showModalLogout(false)} onNext={handleLogout} />}
            {modalAvatar && <AvatarModal onCloseModal={() => showModalAvatar(false)} />}
            {viewFile && <ViewFileModal url={viewFile} onClose={() => setViewFile(null)} />}

            {fullLoader && <Loader title={titleLoader || 'Loading file directory...'} />}

            {csModal && <ContactCSModal onClose={() => showCSModal(false)} message={Helper.getConfigByCode(config, 'C041')} />}
            {openAnnounce === 'open' && <InformationModal onClose={() => setOpenAnnounce('close')} data={Helper.getConfigByCode(config, 'C021')} />}
        </>
    );
}


const NavMobile = ({ onClick, onLogout, onCLickAvatar, user, bgAvatar }: any) => {
    const animate = useStartAnimation()

    return (
        <div className={`md:hidden flex flex-col text-slate-700 dark:text-slate-300 w-11/12 mx-auto pl-2 pb-5 ${animate ? '' : 'translate-x-60 -translate-y-7 opacity-0'} transform transition duration-1000 space-y-2 mt-3`}>
            <div className='flex items-center font-semibold' onClick={onCLickAvatar}>
                <LazyLoadImage effect='blur' alt='' src={baseURLImage + user?.avatar || `https://ui-avatars.com/api/?name=${user?.name || user?.username}&background=${bgAvatar}&color=fff`} className='w-6 h-6 rounded-full mr-2' />
                <h5>{user?.name || user?.username || 'username'}</h5>
            </div>
            <Link className='flex items-center font-semibold' to='/admin'>
                <i className="ri-admin-fill text-xl mr-2.5"></i>
                <h5>Administration</h5>
            </Link>
            {/* <div className='flex item-center'>
                <i className="ri-translate text-xl mr-2.5"></i>
                <p>Translate</p>
            </div> */}
            <div onClick={onLogout} className='flex item-center'>
                <i className="ri-logout-circle-r-line text-xl mr-2.5"></i>
                <p className='flex items-center'>Logout</p>
            </div>
        </div>
    )
}


const Homepage = () => (
    <HomeApps />
)

export default Homepage;