import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
    onClose?: (e: React.MouseEvent<HTMLElement>) => void,
    url: string,
};

const ViewFileModal: React.FC<Props> = ({ onClose, url}) => {
    const extension = url.split('.')[url.split('.').length - 1]

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-[95%] md:w-8/12 2xl:w-7/12 text-slate-700 dark:text-white rounded-lg shadow bg-soft dark:bg-slate-800 p-6 z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-center rounded-t border-b pb-3 border-gray-300 dark:border-gray-600 mb-6 ">
                        <div className="text-base md:text-xl font-semibold text-slate-700 dark:text-white flex item-center space-x-2 lg:space-x-3">
                            <i className={extension==='pdf'? 'bi bi-file-earmark-pdf-fill' : 'bi bi-image-fill'}></i>
                            <h3>Open File</h3>
                        </div>
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <div className='flex items-center justify-center'>
                        {extension === 'pdf'?
                        <iframe className='w-full h-[30rem]' src={url} title={url.split('.')[0]} ></iframe>
                        :
                        <LazyLoadImage className='w-full' effect='blur' alt='' src={url} />
                        }
                    </div>

                    <div className="flex items-center mt-7 pt-4 space-x-4 rounded-b border-t border-gray-300 dark:border-gray-600 ">
                        <button type='reset' className="btn-primary" onClick={onClose}>Close</button>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ViewFileModal;