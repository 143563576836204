import axios from "axios"
import { baseURL } from "./common-api"

export interface UserPayload {
    id?: number
    name?: string,
    username?: string,
    email?: string,
    password?: string,
    password_confirmation?: string,
    whatsapp_number?: string,
	device_name?: string,
    role?: string,
    user_folder?: any,
    user_approved_at?: string
}


export const getOneUser = async (userID: number) => {
    try {
        const data = await axios.get(`${baseURL}/users/${userID}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getAllUser = async () => {
    try {
        const data = await axios.get(baseURL + '/users', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewUser = async (payload: UserPayload) => {
    try {
        const data = await axios.post(baseURL + '/users', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateUser = async (userID: number, payload: UserPayload) => {
    try {
        const data = await axios.put(baseURL + '/users/' + userID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteUser = async (userID: number) => {
    try {
        const data = await axios.delete(baseURL + '/users/' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}

export const changeUserPassword = async (userID: number, payload: UserPayload) => {
    try {
        const data = await axios.put(baseURL + '/users/' + userID + '/change-password', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}

export const approveUser = async (userID: number, value: boolean) => {
    try {
        const payload = {
            approve: value
        }
        const data = await axios.put(baseURL + '/users/' + userID + '/approve', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}


export const getClient = async (search: string) => {
    try {
        const payload = {
            search: search
        }
        const data = await axios.post(baseURL + '/users/client', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}


export const getRoleUser = async () => {
    try {
        const data = await axios.get(baseURL + '/roles', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}

export const changeAvatar = async (userID: number, payload: any) => {
    try {
        const data = await axios.post(`${baseURL}/users/${userID}/change-avatar`, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                'Content-Type': 'multipart/form-data'
                // "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}