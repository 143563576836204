import React, { useState } from 'react';

type Props = {
    title: string,
    icon?: string,
    children?: JSX.Element | string,
    defaultOpen?: boolean
};

const Accordion: React.FC<Props> = ({ title, icon, children, defaultOpen }) => {
    const [isActive, setIsActive] = useState(defaultOpen || false);
  
    return (
      <div className="w-full flex flex-col">
        <div className="cursor-pointer flex justify-between items-center px-5 py-4 font-medium border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" onClick={() => setIsActive(!isActive)}>
            <div className='flex items-center space-x-2.5'>
                <i className={`${icon || 'ri-question-fill'} text-xl`}></i>
                <h3>{title}</h3>
            </div>
          <div className='text-xl'>{isActive ? <i className="ri-arrow-up-s-line"></i> : <i className="ri-arrow-down-s-line"></i>}</div>
        </div>
        {isActive && 
            <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 text-gray-500 dark:text-gray-400">
                {children}
            </div>}
      </div>
    );
}

export default Accordion;