import { useEffect, useState } from 'react';
import { getConfig } from '../api/common-api';

export interface IConfig {
    "id"?: number,
    "code": string,
    "category"?: string,
    "icon"?: string,
    "input_type"?: string,
    "label"?: string,
    "value": string
}

export default function useConfig(existConfig?: any[], configCode?: string) {
    const [config, setConfig] = useState<IConfig[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        async function fetchConfig() {
            setLoading(true)
            const res = await getConfig(configCode || '')

            setLoading(false)
            console.log("Fetching config :", res)
            if (res.status === 200) {
                if (res.data.success) {
                    setConfig(res.data.data)
                    localStorage.setItem('config', JSON.stringify(res.data.data))
                }else{
                    setError(res.data.message)
                }
            }else{
                setError(res.message)
            }
        }

        if(existConfig){
            if(existConfig?.length === 0){
                fetchConfig()
            }else{
                setConfig(existConfig!)
            }
        }else{
            fetchConfig()
        }
    }, [configCode, existConfig])

    return {config, error, loading}
}