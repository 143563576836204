import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ErrorField from '../components/ErrorField';
import appLogo from '../assets/img/logo-sip.png'
import { SubmitHandler, useForm } from 'react-hook-form';
import { getEmailFromTokenURL, IResetPassword, resetPassword } from '../api/common-api';
import Loader from '../components/Loader';
import Helper from '../utils/Helper';
import { toast } from 'react-toastify';
import bgLogin from '../assets/img/bg-login.jpg'


type Props = {};

const ResetPassword: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const navigate = useNavigate()
    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const token = query.get('token')

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<IResetPassword>({ criteriaMode: "all" });

    useEffect(() => {
        localStorage.clear()
    }, [])

    const onSubmitForm: SubmitHandler<IResetPassword> = async ({ password, password_confirmation }) => {
        showLoader(true)

        const res = await getEmailFromTokenURL(token || '')
        console.log("Get email from token URL :", res)

        if(res.status === 200){
            if(res.data.success){
                handleResetPassword(res.data.data.email, password, password_confirmation)
            }else{
                showLoader(false)
                Helper.bulkResponseError(res)
            }
        }else{
            showLoader(false)
            Helper.bulkResponseError(res)
        }
    }

    const handleResetPassword = async (email: string, password: string, password_confirmation: string) => {
        const payload: IResetPassword = {
            token: token || '',
            email,
            password,
            password_confirmation
        }

        const res = await resetPassword(payload)
        console.log("Reset Password :", res)
        showLoader(false)
        if(res.status === 200){
            if(res.data.success){
                setIsSuccess(true)
                toast.success(res.data.message)
                setTimeout(() => {
                    navigate('/auth')
                }, 3000);
            }else{
                Helper.bulkResponseError(res)
            }
        }else{
            Helper.bulkResponseError(res)
        }
    }


    return (
        <>
            <div className="bg-[#eee] min-h-screen flex justify-center items-center font-poppins">
                <div className="relative w-[1366px] h-[768px]">

                    <div className="absolute top-0 left-0 w-full md:w-1/2 h-full bg-white 2xl:rounded-tl-xl 2xl:rounded-bl-xl text-dark z-10">
                        <div className="h-full flex flex-col justify-center items-center space-y-8">

                            <LazyLoadImage effect='blur' src={appLogo} className='w-20 h-20 mx-auto' />

                            <div className='text-center'>
                                <h1 className="text-2xl md:text-3xl font-extrabold">Reset your password</h1>
                                <p className="mt-5 px-10">Please create a new password that you don't use before</p>
                            </div>


                            <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-7 px-3 md:px-0 w-11/12 md:w-7/12">
                                {isSuccess &&
                                <div className='bg-green-100 rounded-lg shadow-md px-5 py-5'>
                                    <p>Your password successfully reset, redirect to login page..</p>
                                </div>
                                }
                                <div>
                                    <label className="font-semibold" htmlFor="password">New password</label>
                                    <input id="password" type="password" autoComplete="password" className="text-input"
                                        {...register("password", {
                                            required: "Password is required.",
                                            minLength: { value: 5, message: "Password must exceed 4 characters." }
                                        })} />
                                    <ErrorField errors={errors} name='password' />
                                </div>

                                <div className='pb-3'>
                                    <label className="font-semibold" htmlFor="password_confirmation">New password (Confirm)</label>
                                    <input id="password_confirmation" type="password" autoComplete="password_confirmation" className="text-input"
                                        {...register("password_confirmation", {
                                            required: "Confirm Password is required.",
                                            minLength: { value: 5, message: "Confirm Password must exceed 4 characters." }
                                        })} />
                                    <ErrorField errors={errors} name='password_confirmation' />
                                </div>

                                <button type='submit' className="btn-submit" >
                                    Reset password
                                </button>
                            </form>

                            <div className='font-medium text-sm flex items-center justify-center'>
                                <Link to='/auth' className='text-primary hover:text-orange-500 ml-1'>Back to Sign in</Link>
                            </div>

                            <div className='font-medium text-sm flex items-center justify-center text-dark'>
                                © Electronic System Administration Pajak v1.0
                            </div>

                        </div>
                    </div>

                    <LazyLoadImage src={bgLogin} alt="" className='hidden md:block h-full w-full object-fill z-0 2xl:rounded-xl' />
                </div>
            </div>

            {loader && <Loader />}
        </>
    );
}

export default ResetPassword;