import axios from "axios";
import { UserPayload } from "./user-api";

// const herokuCors = 'https://cors-anywhere.herokuapp.com/'
// export const baseURL = herokuCors + "http://efaktur.api.katapanda.com/api"                          // {Production}

// export const baseURL = "https://efaktur.api.katapanda.com/api"                          // {Production}
// export const baseURLImage = 'https://efaktur.api.katapanda.com/'

export const baseURL = "https://esap.api.camatgo.id/api"                          // {Production}
export const baseURLImage = 'https://esap.api.camatgo.id/'

export interface IResetPassword {
	"token": string,
	"email": string,
	"password": string,
	"password_confirmation": string
}

export const loginAPI = async (payload: UserPayload) => {
    try {
        const data = await axios.post(`${baseURL}/login`, payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const registerUser = async (payload: UserPayload) => {
    try {
        const data = await axios.post(`${baseURL}/register`, payload)
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getLoginInfo = async (token: string) => {
    try {
        const data = await axios.get(`${baseURL}/user-login`, {
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const logout = async () => {
    try {
        const data = await axios.post(`${baseURL}/logout`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const resetPasseordLink = async (email: string) => {
    try {
        const payload = { email }
        const data = await axios.post(`${baseURL}/send-link-reset-password`, payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getEmailFromTokenURL = async (token: string) => {
    try {
        const payload = { token }
        const data = await axios.post(`${baseURL}/get-email`, payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const resetPassword = async (payload: IResetPassword) => {
    try {
        const data = await axios.post(`${baseURL}/reset-password`, payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getConfig = async (configCode: string, token?: string) => {
    try {
        const payload = {
            "code": configCode
        }

        const data = await axios.post(`${baseURL}/configs`, payload, {
            headers: {
                authorization: `Bearer ${token || localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateConfig = async (array: any[]) => {
    try {

        const data = await axios.put(`${baseURL}/configs`, array, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getStatistics = async (date: string, last_day: string) => {
    try {
        const payload = {
            "date": date, 
            "last_day": last_day
        }

        const data = await axios.post(`${baseURL}/dashboards/statistics`, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}