import { useEffect, useRef, useState } from "react";
import { IFolderAndFile } from "../api/document-api";


type Props = {
    type?: 'file' | 'folder'
    items?: IFolderAndFile[],
    actionType?: string | null,
    onOpen?: () => void
    onUpload?: () => void
    onMove?: () => void
    onCopy?: () => void
    onPaste?: () => void
    onDownload?: () => void
    onShare?: () => void
    onRename?: () => void
    onDelete?: () => void
};

const DropdownGroupAction: React.FC<Props> = ({ type, items, onMove, onCopy, onDownload, onShare, onRename, onDelete }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const user = localStorage.getItem('dataUser') ? JSON.parse(localStorage.getItem('dataUser') || '') : {}
    const role = user ? parseInt(user.role) : 99


    const trigger = useRef<any>(null);
    const dropdown = useRef<any>(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: any) => {
        if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: any) => {
        if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });



    const handleClick = (eventClick: any) => {
        setDropdownOpen(false)

        if (eventClick) {
            eventClick()
        }
    }

    return (
        <div className="relative">
            <button ref={trigger} aria-haspopup="true" aria-expanded={dropdownOpen} onClick={() => setDropdownOpen(!dropdownOpen)} className="menu rounded-md focus:outline-none flex items-center bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-400 text-white px-2 py-1">
                <i className="ri-settings-line text-lg mr-1.5"></i>
                <p>Group Action</p>
            </button>

            {dropdownOpen &&
                <div className="absolute origin-top-right top-full right-0 w-max text-slate-600 dark:text-slate-200 bg-white dark:bg-slate-700 border border-gray-200 dark:border-gray-600 py-1.5 px-1 rounded shadow-lg overflow-hidden mt-1" style={{zIndex: 9999}} >
                    {role === 1 ?
                        <ul>
                            {type === 'file' &&
                                <>
                                    <li>
                                        <button className="font-medium hover:bg-orange-400 hover:text-white rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={() => handleClick(onMove)}>
                                            <i className="ri-drag-move-2-line mr-3"></i>Move
                                        </button>
                                    </li>
                                    <li>
                                        <button className="font-medium hover:bg-orange-400 hover:text-white rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={() => handleClick(onCopy)}>
                                            <i className="ri-file-copy-2-line mr-3"></i>Copy
                                        </button>
                                    </li>
                                    <div className='w-full h-[1px] bg-slate-400 dark:bg-slate-900 my-0.5'></div>
                                </>
                            }
                            <li>
                                <button className="font-medium hover:bg-orange-400 hover:text-white rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={() => handleClick(onDownload)}>
                                    <i className="ri-download-2-line mr-3"></i>Download
                                </button>
                            </li>
                            {type === 'file' &&
                                <li>
                                    <button className="font-medium hover:bg-orange-400 hover:text-white rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={() => handleClick(onShare)}>
                                        <i className="ri-share-forward-2-fill mr-3"></i>Share
                                    </button>
                                </li>
                            }
                            <div className='w-full h-[1px] bg-slate-400 dark:bg-slate-900 my-0.5'></div>
                            {items?.length! < 2 &&
                                <li>
                                    <button className="font-medium hover:bg-orange-400 hover:text-white rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={() => handleClick(onRename)}>
                                        <i className="bi bi-pencil-square mr-3"></i>Rename
                                    </button>
                                </li>
                            }
                            <li>
                                <button className="font-medium hover:bg-orange-400 hover:text-white rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={() => handleClick(onDelete)}>
                                    <i className="bi bi-trash mr-3"></i>Delete
                                </button>
                            </li>
                        </ul>
                        :
                        <ul>
                            <li>
                                <button className="font-medium hover:bg-orange-400 hover:text-white rounded flex items-center py-1 pl-3 pr-8 w-full" onClick={() => handleClick(onDownload)}>
                                    <i className="ri-download-2-line mr-3"></i>Download
                                </button>
                            </li>
                        </ul>
                    }
                </div>
}
        </div>
    )
}

export default DropdownGroupAction