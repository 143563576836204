import Multiselect from 'multiselect-react-dropdown';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DocumentAPI from '../../api/document-api';
import { UserPayload } from '../../api/user-api';
import Helper from '../../utils/Helper';
import ErrorField from '../ErrorField';
import NewFolderModal from './NewFolderModal';


const LIST_ROLE = [
    { "id": 1, "name": "Administrator", "description": "Role has all access to menus", "created_at": "2022-03-29T19:21:01.000000Z", "updated_at": "2022-03-29T19:21:01.000000Z" },
    { "id": 2, "name": "Client", "description": "Role has limit access to menus", "created_at": "2022-03-29T19:21:01.000000Z", "updated_at": "2022-03-29T19:21:01.000000Z" }
]


type Props = {
    data?: any,
    roleList?: any[],
    onSubmit: (data: UserPayload) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const UserForm: React.FC<Props> = ({ data, roleList, onSubmit, onClose }) => {
    const [newFolderModal, showNewFolderModal] = useState(false)
    const [folderList, setFolderList] = useState<any[]>([])
    const [selected, setSelected] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)

    const multiSelectRef = useRef<any>(null)

    const fetchFolder = useCallback(async () => {
        setIsLoading(true)
        const res = await DocumentAPI.showFolder('')
        console.log("show folder :", res)
        
        if (res.status === 200 && res.data.success) {
            setFolderList(res.data.data)
            console.log(data?.user_folder?.split(','))
            if (data && data.user_folder) {
                var duplicates = res.data.data.filter(function (item: any) {
                    return data.user_folder.split(',').indexOf('uploads/' + item.path) !== -1;
                });
            }

            setSelected(duplicates)
            setIsLoading(false)
        }else{
            Helper.bulkResponseError(res)
            setIsLoading(false)
        }
        
    }, [data])

    useEffect(() => {
        fetchFolder()
    }, [fetchFolder])
    
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])


    const onSubmitForm: SubmitHandler<UserPayload> = ({ name, username, password, password_confirmation, email, role, whatsapp_number }) => {
        if (!data) {
            if (password !== password_confirmation) {
                toast.warning("Password not match")
                return
            }
        }

        const selectedFolder = multiSelectRef.current?.getSelectedItems()
        const folder = selectedFolder.map((item: any) => 'uploads/' + item.path).join(',')
        // console.log(folder)

        const payload = {
            name, username, password, password_confirmation, email, role, whatsapp_number: Helper.indonesiaPhone(whatsapp_number!),
            user_folder: folder
        } as UserPayload

        if (data) {
            delete payload.password
            delete payload.password_confirmation
        }


        onSubmit(payload)
    }

    return <>
        <div className="modal-form overflow-y-auto">
            <div className="modal-form-outside" onClick={onClose} />
            {/* <!-- Modal content --> */}
            <div className="w-11/12 md:w-8/12 2xl:w-6/12 rounded-lg shadow bg-slate-800 z-50 overflow-y-auto" style={{maxHeight: '97vh'}}>

                <div className="flex justify-end p-2">
                    <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center hover:bg-gray-800 hover:text-white"
                        onClick={onClose}
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 space-y-5 lg:px-8 sm:pb-6 xl:pb-8">
                    <div className="text-xl font-medium text-white pb-4 flex item-center space-x-2">
                        {data ? <i className="ri-user-settings-fill"></i> : <i className="ri-user-add-fill"></i>}
                        <h3>{data ? data.username : "Create User"}</h3>
                    </div>

                    {isLoading ?
                    <div className='text-slate-100 font-medium flex justify-center items-center h-[21rem]'>
                        <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <p>Fetching user's folder...</p>
                    </div>
                    :
                    <div className='md:flex space-y-5 md:space-y-0 space-x-0 md:space-x-5'>
                        <div className='space-y-5 w-full'>
                            <div className="w-full">
                                <label htmlFor="name" className="label-form">Fullname</label>
                                <input type="text" id="name" className="input-form" placeholder="Full Name"
                                    {...register('name', {
                                        required: 'name is required',
                                        maxLength: { value: 25, message: 'name is too long' }
                                    })}
                                />
                                <ErrorField errors={errors} name='name' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="username" className="label-form">Username</label>
                                <input type="text" id="username" className="input-form" placeholder="Username"
                                    {...register('username', {
                                        required: 'Username is required',
                                    })}
                                />
                                <ErrorField errors={errors} name='username' />
                            </div>
                            {!data &&
                                <div className="space-y-5">
                                    <div className="w-full">
                                        <label htmlFor="password" className="label-form">Passsword</label>
                                        <input type="password" id="password" placeholder="••••••••" className="input-form"
                                            {...register("password", {
                                                required: "Password is required.",
                                                minLength: { value: 5, message: "Password must exceed 4 characters." }
                                            })}
                                        />
                                        <ErrorField errors={errors} name='password' />
                                    </div>
                                    <div className="w-full">
                                        <label htmlFor="password_confirmation" className="label-form">Confirm Password</label>
                                        <input type="password" id="password_confirmation" className="input-form" placeholder="••••••••"
                                            {...register("password_confirmation", { required: "Confirm password is required." })} />
                                        <ErrorField errors={errors} name='password_confirmation' />
                                    </div>
                                </div>
                            }
                            <div className="w-full">
                                <label htmlFor="email-address" className="label-form">Email</label>
                                <input id="email-address" type="text" autoComplete="email" className="input-form" placeholder='Email Address'
                                    {...register("email", {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address."
                                        }
                                    })} />
                                <ErrorField errors={errors} name='email' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="whatsapp_number" className="label-form">Whatsapp Number</label>
                                <input type="tel" id="whatsapp_number" className="input-form" placeholder="62812345xxxx"
                                    {...register("whatsapp_number", {
                                        minLength: { value: 9, message: 'Whatsapp number is too short' },
                                        maxLength: { value: 14, message: 'Whatsapp number is too long' }
                                    })} />
                                <ErrorField errors={errors} name='whatsapp_number' />
                            </div>
                        </div>
                        <div className='space-y-5 w-full'>
                            <div className="w-full">
                                <label htmlFor="role" className="label-form">Role</label>
                                <select id="role" className="input-form" {...register('role', { required: 'Role is required' })}>
                                    <option value=''>- Select Role -</option>
                                    {roleList && roleList?.length > 0 ?
                                        roleList!.map((item: any, key: any) =>
                                            <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                        )
                                        :
                                        LIST_ROLE!.map((item: any, key: any) =>
                                            <option key={key} value={item.id}>{item.name.toUpperCase()}</option>
                                        )
                                    }
                                </select>
                                <ErrorField errors={errors} name='role' />
                            </div>
                            <div className="w-full flex items-center">
                                <div className='w-full'>
                                    <label htmlFor="user_folder" className="label-form">User Folder</label>
                                    {/* <input id="user_folder" type="text" autoComplete="user_folder" className="input-form" {...register("user_folder")} />
                                    <ErrorField errors={errors} name='user_folder' /> */}
                                    <Multiselect
                                        ref={multiSelectRef}
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={function noRefCheck() { }}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={function noRefCheck() { }}
                                        options={folderList}
                                        selectedValues={selected}
                                        displayValue="path"
                                        className='w-full px-1 py-1 text-sm rounded-lg bg-slate-600 border-none placeholder-slate-400'
                                        placeholder='Select folder'
                                        // hidePlaceholder
                                        avoidHighlightFirstOption
                                        showCheckbox
                                        keepSearchTerm
                                    />
                                </div>
                                <i className="bi bi-folder-plus w-12 h-10 bg-slate-600 text-white rounded-md ml-3 flex justify-center items-center text-xl mt-[1.7rem] cursor-pointer hover:bg-opacity-50" onClick={()=>showNewFolderModal(true)}></i>
                            </div>
                        </div>
                    </div>
                    }

                    {/* <!-- Modal footer --> */}
                    <div className={`flex items-center pt-6 ${data? 'md:pt-[7rem]' : 'md:pt-4 2xl:pt-6' } space-x-4`}>
                        <button type='submit' className="btn-primary px-7">Save</button>
                        <button type='reset' className="btn-secondary" onClick={onClose}>Cancel</button>
                    </div>

                </form>
            </div>
        </div>
        {newFolderModal && <NewFolderModal 
            onClose={()=>{
                showNewFolderModal(false)
            }}
            onSubmit={(bool)=>{
                setFolderList([])
                fetchFolder()
                showNewFolderModal(false)
            }}
        />}
    </>
}

export default UserForm;