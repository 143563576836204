// ThemeContext.js
import { createContext, useEffect, useState } from 'react';

const getInitialTheme = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const storedPrefs = window.localStorage.getItem('color-theme');
        if (typeof storedPrefs === 'string') {
            return storedPrefs;
        }

        const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
        if (userMedia.matches) {
            return 'dark';
        }
    }

   return 'light' // light theme as the default;
};

const getInitialUserStorage = () => {
    if (typeof window !== 'undefined' && window.localStorage) {
        const storedPrefs = window.localStorage.getItem('dataUser');
        if (typeof storedPrefs === 'string') {
            try {
                return JSON.parse(storedPrefs)
            } catch (error) {
                console.log(error)
                return {}
            }
        }
    }

   return {} // light theme as the default;
};

const getInitialAnnouncement = () => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
        const storedPrefs = window.sessionStorage.getItem('openAnnounce');
        return storedPrefs
    }

   return 'open' // light theme as the default;
};


export const ThemeContext = createContext();

export const ThemeProvider = ({ /* initialTheme, */ children }) => {
    const [theme, setTheme] = useState(getInitialTheme);
    const [userStorage, setUserStorage] = useState(getInitialUserStorage)
    const [openAnnounce, setOpenAnnounce] = useState(getInitialAnnouncement)

    const rawSetTheme = (rawTheme) => {
        const root = window.document.documentElement;
        const isDark = rawTheme === 'dark';

        root.classList.remove(isDark ? 'light' : 'dark');
        root.classList.add(rawTheme);

        localStorage.setItem('color-theme', rawTheme);
    };

    // if (initialTheme) {
    //     rawSetTheme(initialTheme);
    // }

    useEffect(() => {
        rawSetTheme(theme);
    }, [theme]);

    useEffect(() => {
        if(userStorage !=='' && userStorage !== null && userStorage !== null && userStorage !== {}){
            localStorage.setItem('dataUser', JSON.stringify(userStorage))
        }
    }, [userStorage])

    useEffect(() => {
        if(openAnnounce !=='' && openAnnounce !== null && openAnnounce !== undefined){
            sessionStorage.setItem('openAnnounce', openAnnounce)
        }
    }, [openAnnounce])

    return (
        <ThemeContext.Provider value={{ 
            theme, setTheme, 
            userStorage, setUserStorage, 
            openAnnounce, setOpenAnnounce 
        }}>
            {children}
        </ThemeContext.Provider>
    );
};