import React, { useState, useCallback, Fragment, FC, useContext } from 'react'
import Cropper from 'react-easy-crop'
import { Range } from "react-range"
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from '../../utils/canvasUtils'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { baseURLImage, getLoginInfo } from '../../api/common-api'
import Helper from '../../utils/Helper'
import Loader from '../Loader'
import { toast } from 'react-toastify'
import { ThemeContext } from '../../contexts/ThemeContext'
import { changeAvatar } from '../../api/user-api'

const ORIENTATION_TO_ANGLE: any = {
    '3': 180,
    '6': 90,
    '8': -90,
}

interface Props {
    onCloseModal: (e: React.MouseEvent<HTMLElement>) => any
}

const AvatarModal: FC<Props> = ({ onCloseModal }) => {
    const [imageSrc, setImageSrc] = useState<any>(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState<any>(null)
    const [values, setValues] = useState([1])
    const [values2, setValues2] = useState([0])

    const [loader, showLoader] = useState(false)
    const [modalViewAvatar, showModalViewAvatar] = useState(false)
    const [titleLoader, setTitleLoader] = useState('Saving image...')

    const { userStorage, setUserStorage } = useContext(ThemeContext)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', croppedImage )
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [imageSrc, croppedAreaPixels, rotation])

    // eslint-disable-next-line
    const onClose = useCallback(() => {
        setCroppedImage(null)
    }, [])

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl = await readFile(file)

            // apply rotation if needed
            const orientation = await getOrientation(file)
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageSrc(imageDataUrl)
        }
    }

    const handleSavePhoto = async () => {
        
        // setImageSrc(null)
        // setCroppedImage(null)
        
        // toast.info('Saving image...')

        showLoader(true)

        const formData = new FormData()
        formData.append('avatar', croppedImage.file);
        
        const res = await changeAvatar(userStorage.id, formData)
        console.log("Save Image :", res)

        if(res.status === 200){
            toast.success(res.data.message)
            setTitleLoader("Please wait...")

            const { data } = await getLoginInfo(localStorage.getItem('authToken') || '')
            console.log('Refreshing user local :', data)
            setUserStorage(data)
            
            setImageSrc(null)
            setCroppedImage(null)

            showLoader(false)
        }else{
            Helper.bulkResponseError(res)
            showLoader(false)
        }
    }

    return (
        <>
        <div className='modal-form'>
            <div className='modal-form-outside' onClick={onCloseModal} />
            <div className="w-11/12 md:w-5/12 2xl:w-[30%] text-slate-700 bg-soft dark:bg-slate-800 dark:text-white rounded-lg shadow z-50 overflow-y-auto overflow-x-hidden p-5" style={{ maxHeight: '90vh' }}>
                <div className="flex justify-between items-start pb-3 md:pb-5 md:px-5 pt-1 rounded-t border-b border-slate-400 dark:border-gray-600 mb-5">
                    <div className="md:text-xl font-medium flex item-center space-x-2 lg:space-x-3">
                        <i className="bi bi-person-badge"></i>
                        <h3>Profile</h3>
                    </div>
                    <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                        onClick={onCloseModal}
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>
                {imageSrc ? (
                    <Fragment>
                        <div className='w-full h-64 relative'>
                            <Cropper image={imageSrc} crop={crop} rotation={rotation} zoom={zoom} aspect={1 / 1}
                                onCropChange={setCrop}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </div>
                        <div className=''>
                            <div className=''>
                                <label>Zoom {zoom?.toFixed(1)}x</label>
                                <Range step={0.1} min={1} max={3} values={values} onChange={(values) => { setValues(values); setZoom(values[0]) }}
                                    renderTrack={({ props, children }) => (
                                        <div {...props} className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md" >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div {...props}
                                            className="w-5 h-5 transform translate-x-10 bg-indigo-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <label>Rotation {rotation}°</label>
                                <Range step={1} min={0} max={360} values={values2} onChange={(values) => { setValues2(values); setRotation(values[0]) }}
                                    renderTrack={({ props, children }) => (
                                        <div {...props} className="w-full h-3 pr-2 my-4 bg-gray-200 rounded-md" >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div {...props}
                                            className="w-5 h-5 transform translate-x-10 bg-indigo-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        />
                                    )}
                                />
                            </div>
                            <div className='flex items-center justify-center mt-5'>
                                <button onClick={showCroppedImage} className='bg-indigo-600 rounded-lg px-3.5 py-[0.7rem] text-sm text-white hover:bg-opacity-70 flex items-center justify-center space-x-2' >
                                    <i className="bi bi-eye-fill flex justify-center items-center text-lg"></i>
                                    <p>Preview</p>
                                </button>
                            </div> 

                            {croppedImage && 
                                <div className='flex items-center justify-center flex-col mt-5'>
                                    <LazyLoadImage effect='blur' src={croppedImage.base64} alt='' className='w-10/12 mx-auto rounded-xl border-2 border-slate-400 dark:border-slate-500'/>
                                    <div className='flex items-center space-x-3'>
                                        <button onClick={()=>{setImageSrc(null); setCroppedImage(null)}} className='btn-secondary' >Cancel</button>
                                        <button onClick={handleSavePhoto} className='bg-indigo-600 rounded-lg px-7 py-[0.7rem] text-sm text-white hover:bg-opacity-70 my-5' >Save</button>
                                    </div>
                                </div> 
                            }
                        </div>
                    </Fragment>
                ) : (
                    <div>
                        <div className='flex flex-col justify-center items-center'>
                            <LazyLoadImage effect='blur' alt='' src={baseURLImage + userStorage?.avatar || `https://ui-avatars.com/api/?name=${userStorage?.name || userStorage?.username}&background=fb923c&color=fff`} className='w-20 h-20 md:w-24 md:h-24 rounded-full mr-2 cursor-pointer' onClick={()=>showModalViewAvatar(true)} />
                            <label htmlFor='foto' className='text-indigo-500 mt-4 font-medium cursor-pointer hover:underline hover:text-indigo-400'>Change profile photo</label>
                            <input id='foto' type="file" onChange={onFileChange} accept="image/*" className='hidden' />
                        </div>
                        <div className='flex flex-col space-y-5 p-2.5 md:p-5 pt-0 font-medium dark:font-normal mb-3 mt-3 md:-mt-2'>
                            <div className='flex flex-col'>
                                <label className='text-xs text-slate-500 dark:text-slate-400'>Name</label>
                                <input readOnly className='border-b border-slate-300 dark:border-slate-500 py-1.5 outline-none font-medium dark:font-normal cursor-default' defaultValue={userStorage?.name || ''} />
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-xs text-slate-500 dark:text-slate-400'>Username</label>
                                <input readOnly className='border-b border-slate-300 dark:border-slate-500 py-1.5 outline-none font-medium dark:font-normal cursor-default' defaultValue={userStorage?.username || ''} />
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-xs text-slate-500 dark:text-slate-400'>Email</label>
                                <input readOnly className='border-b border-slate-300 dark:border-slate-500 py-1.5 outline-none font-medium dark:font-normal cursor-default' defaultValue={userStorage?.email || ''} />
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-xs text-slate-500 dark:text-slate-400'>Phone Number</label>
                                <input readOnly className='border-b border-slate-300 dark:border-slate-500 py-1.5 outline-none font-medium dark:font-normal cursor-default' defaultValue={userStorage?.whatsapp_number || ''} />
                            </div>
                            <div className='flex flex-col'>
                                <label className='text-xs text-slate-500 dark:text-slate-400'>Join at</label>
                                <input readOnly className='border-b border-slate-300 dark:border-slate-500 py-1.5 outline-none font-medium dark:font-normal cursor-default' defaultValue={moment(userStorage?.created_at).format('dddd, DD MMM YYYY') || ''} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        {loader && <Loader title={titleLoader} />}
        {modalViewAvatar && <ModalViewAvatar userStorage={userStorage} onClose={()=>showModalViewAvatar(false)} />}
        </>
    )
}

function readFile(file: any) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const ModalViewAvatar = ({userStorage, onClose}: any) => {
    return (
        <>
            <div className='modal-form'>
                <div className='modal-form-outside opacity-90' onClick={onClose} />
                <div className="w-11/12 md:w-5/12 2xl:w-[30%] z-50 flex justify-center items-center border-4 border-slate-500 rounded-lg">
                    <LazyLoadImage effect='blur' alt='' src={baseURLImage + userStorage?.avatar || `https://ui-avatars.com/api/?name=${userStorage?.name || userStorage?.username}&background=fb923c&color=fff`} className='w-full h-full rounded' />
                </div>
            </div>
        </>
    )
}

export default AvatarModal