import {
    GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton
} from '@mui/x-data-grid';
import SearchField from './SearchField';

interface QuickSearchToolbarProps {
    clearSearch?: () => void;
    onSearchChange?: (e: React.FormEvent<HTMLInputElement>) => any;
    searchText?: string;
    pageSize?: number,
    onPageSizeChange?: (e: React.FormEvent<HTMLSelectElement>) => any,
    rowsPerPageOptions?: number[],
    enableCreate?: boolean,
    onCreate?: (e: React.MouseEvent<HTMLElement>) => void
}

const DataGridToolbar = ({ onSearchChange, clearSearch, searchText, pageSize, onPageSizeChange, rowsPerPageOptions, enableCreate, onCreate }: QuickSearchToolbarProps) => {
    return (
        <div className='mb-5'>
            <div className='flex justify-between p-2'>
                <div className='hidden md:block'>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </div>
                {enableCreate && 
                <button className='btn-primary flex justify-center items-center space-x-2 py-[6px] w-full md:w-max' onClick={onCreate}>
                    <i className="ri-add-circle-fill text-2xl"></i>
                    <p>Create New</p>
                </button>
                }
            </div>
            <div className='my-2 px-3 lg:mb-1 flex items-center justify-between'>
                <SearchField placeholder='Search data...' value={searchText} onChange={onSearchChange} clearSearch={clearSearch} />
                <select value={pageSize} onChange={onPageSizeChange} className='bg-transparent cursor-pointer border-b border-slate-300 dark:border-slate-800 py-2 px-4 shadow-sm focus:rounded-xl focus:outline-none focus:border-orange-400 focus:ring-orange-400 focus:ring-1'>
                    {rowsPerPageOptions?.map((item, key) =>
                        <option value={item} key={key}>{item}</option>
                    )}
                </select>
            </div>
        </div>
    )
}

export default DataGridToolbar  