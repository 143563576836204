import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DocumentAPI from '../../api/document-api';
import { getClient, UserPayload } from '../../api/user-api';
import Helper from '../../utils/Helper';
import Loader from '../Loader';

type Props = {
    onClose?: (e: React.MouseEvent<HTMLElement>) => void,
    pathFile?: string | string[]
};

const ShareModal: React.FC<Props> = ({ onClose, pathFile }) => {
    const [titleLoader, setTitleLoader] = useState('')
    const [loader, showLoader] = useState(false)
    const [link, setLink] = useState('')
    const [copySuccess, setCopySuccess] = useState(false);
    const [clients, setClients] = useState<UserPayload[]>([])

    useEffect(() => {
        async function fetchClient() {
            const res = await getClient('')
            console.log('Fetch client :', res)
            if (res.status === 200) {
                if (res.data.success) {
                    setClients(res.data.data)
                }
            }
        }

        fetchClient()
    }, [])


    const generateLink = async () => {
        setCopySuccess(false)
        showLoader(true)
        setTitleLoader('Generate link...')

        let payload: string[] = []
        if (typeof pathFile === 'string') {
            payload = [pathFile]
        } else {
            payload = pathFile as string[]
        }
        const res = await DocumentAPI.shareFileLink(payload!)

        console.log("generate link :", res)
        showLoader(false)
        if (res.status === 200) {
            if (res.data.success) {
                setLink(res.data.data.link)
            } else {
                Helper.bulkResponseError(res)
            }
        } else {
            Helper.bulkResponseError(res)
        }
    }
    

    const sendEmail = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { email } = e.target

        handleResponseEmail(email.value)

    }

    const sendEmailDropdown = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { mail_dropdown } = e.target

        handleResponseEmail(mail_dropdown.value)
    }


    const handleResponseEmail = async (email: string) => {
        showLoader(true)
        setTitleLoader('Sending email...')

        let attachment: string[] = []
        if (typeof pathFile === 'string') {
            attachment = [pathFile]
        } else {
            attachment = pathFile as string[]
        }

        const res = await DocumentAPI.shareFileEmail(email, attachment)

        console.log("SendEmail :", res)
        showLoader(false)
        if (res.status === 200) {
            if (res.data.success) {
                toast.success("File sent to : " + email)
            } else {
                Helper.bulkResponseError(res)
            }
        } else {
            Helper.bulkResponseError(res)
        }
    }


    function copyToClipboard() {
        navigator.clipboard.writeText(link)
        setCopySuccess(true)
    };

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-6/12 2xl:w-5/12 text-slate-700 bg-soft dark:bg-slate-800 dark:text-white rounded-lg shadow px-2.5 py-6 md:p-6 z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-center rounded-t border-b pb-3 border-slate-400 dark:border-gray-600 mb-7 ">
                        <div className="text-base md:text-xl font-semibold flex item-center space-x-2 lg:space-x-3">
                            <i className="ri-share-fill"></i>
                            <h3>Share</h3>
                        </div>
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <div className='flex flex-col lg:px-5'>
                        <button className='btn-primary flex items-center px-4 py-1.5 w-max mx-auto mb-4' onClick={generateLink}>
                            <i className='ri-link-m text-xl md:mr-1.5'></i>
                            <p>Generate Link</p>
                        </button>
                        {link &&
                            <div className='flex justify-between items-center mb-3 px-3 lg:px-5 py-2.5 border-2 border-slate-400 dark:border-slate-700 text-slate-500 dark:text-slate-200 rounded-lg'>
                                <input type='text' defaultValue={link} className='outline-none w-full' readOnly />
                                <button className='btn-primary flex items-center px-2.5 md:px-4 py-1.5 ml-6 w-40' onClick={copyToClipboard}>
                                    {copySuccess ? <i className='bi bi-clipboard-fill text-lg mr-1 md:mr-2'></i> : <i className='bi bi-clipboard text-lg md:mr-1.5'></i>}
                                    <p>{copySuccess ? 'Copied!' : 'Copy Link'}</p>
                                </button>
                            </div>
                        }
                        <div className='flex justify-center items-center relative mt-5 mb-4'>
                            <span className='absolute w-full h-[1px] bg-slate-300 dark:bg-slate-700 z-0' />
                            <p className='z-10 px-5 bg-soft dark:bg-slate-800'>Or share with email</p>
                        </div>
                        <form className='mb-5' onSubmit={sendEmailDropdown}>
                        <label className='label-form text-slate-700 dark:text-slate-300' htmlFor='mail_dropdown'>Select Email</label>
                            <div className='flex'>
                                <select className='input-form focus:outline-none bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-500 dark:placeholder-slate-400 text-slate-600 dark:text-white' id='mail_dropdown' name='mail_dropdown'>
                                    <option>- Select Client Email -</option>
                                    {clients.map((client, key) =>
                                        <option key={key} value={client.email}>[{client.name}] {client.email}</option>
                                    )}
                                </select>
                                <button className='btn-primary flex items-center px-4 py-1.5 ml-5 lg:md-0 w-24' type='submit'>
                                    <i className='bi bi-envelope text-lg mr-1 md:mr-2'></i>
                                    <p>Send</p>
                                </button>
                            </div>
                        </form>
                        <form onSubmit={sendEmail}>
                            <label className='label-form text-slate-700 dark:text-slate-300' htmlFor='email'>Or Input Email</label>
                            <div className='flex'>
                                <input className='input-form focus:outline-none bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-500 dark:placeholder-slate-400 text-slate-600 dark:text-white' type='email' id='email' name='email' required placeholder='Enter your email' />
                                <button className='btn-primary flex items-center px-4 py-1.5 ml-5 lg:md-0 w-24' type='submit'>
                                    <i className='bi bi-envelope text-lg mr-1 md:mr-2'></i>
                                    <p>Send</p>
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="flex items-center mt-7 pt-4 space-x-4 rounded-b border-t border-slate-400 dark:border-gray-600">
                        <button type='reset' className="btn-primary" onClick={onClose}>Close</button>
                    </div>

                </div>
            </div>
            {loader && <Loader title={titleLoader} />}
        </>
    );
}

export default ShareModal;