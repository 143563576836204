import axios from "axios"
import { baseURL } from "./common-api"

export interface CustomerServicePayload {
    id?: number
    name?: string,
    whatsapp_number?: string,
	gender?: string,
	status_active?: boolean
}


export const getOneCustomerService = async (csId: number) => {
    try {
        const data = await axios.get(`${baseURL}/customer_services/${csId}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getAllCustomerService = async (optionalUrl?: string) => {
    const endpoint = optionalUrl? optionalUrl : ''
    try {
        const data = await axios.get(baseURL + '/customer_services' + endpoint, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewCustomerService = async (payload: CustomerServicePayload) => {
    try {
        const data = await axios.post(baseURL + '/customer_services', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateCustomerService = async (csId: number, payload: CustomerServicePayload) => {
    try {
        const data = await axios.put(baseURL + '/customer_services/' + csId, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteCustomerService = async (csId: number) => {
    try {
        const data = await axios.delete(baseURL + '/customer_services/' + csId, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("authToken")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error: any) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error)) 
    }
}