import React, { useEffect, useState } from 'react';
import { CustomerServicePayload, getAllCustomerService } from '../../api/cs-api';
import Helper from '../../utils/Helper';

type Props = {
    onClose?: (e: React.MouseEvent<HTMLElement>) => void,
    message?: string
};

const ContactCSModal: React.FC<Props> = ({ onClose, message }) => {
    const [dataCS, setDataCS] = useState<CustomerServicePayload[] | []>([])
    const [loader, showLoader] = useState(false)

    useEffect(() => {
        showLoader(true)
        async function fetchCS() {
            const res = await getAllCustomerService('/active')

            console.log("CS :", res)
            showLoader(false)
            if (res.status === 200) {
                if (res.data.success) {
                    setDataCS(res.data.data)
                } else {
                    Helper.bulkResponseError(res)
                }
            } else {
                Helper.bulkResponseError(res)
            }
        }

        fetchCS()
    }, [])

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-6/12 2xl:w-5/12 text-slate-700 dark:text-white rounded-lg shadow bg-soft dark:bg-slate-800 p-6 z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-center rounded-t border-b pb-3 border-gray-300 dark:border-gray-600 mb-6 ">
                        <div className="text-base md:text-xl font-semibold text-slate-700 dark:text-white flex item-center space-x-2 lg:space-x-3">
                            <i className="ri-customer-service-2-fill"></i>
                            <h3>Contact Customer Service</h3>
                        </div>
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <div className='flex flex-col space-y-3 lg:px-5'>
                        {loader ?
                            <div className='text-slate-700 dark:text-slate-100 font-medium flex justify-center items-center h-40'>
                                <svg className="animate-spin mr-3 h-5 w-5 text-slate-700 dark:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <p>Fetching customer service...</p>
                            </div>
                            : dataCS.length > 0 ?

                                dataCS.map((cs, key) =>
                                    <div className='flex justify-between items-center px-3 lg:px-5 py-3 border-2 border-slate-300 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200' key={key}>
                                        <div className='flex items-center font-medium'>
                                            <i className='bi bi-person-badge text-2xl mr-3 bg-slate-200 dark:bg-slate-600 rounded-full w-10 h-10 flex justify-center items-center'></i>
                                            <p>{cs.name}</p>
                                        </div>
                                        <a className='btn-primary flex items-center px-4 py-1.5 ml-5 lg:md-0' href={`https://api.whatsapp.com/send?phone=${cs.whatsapp_number}&text=${message || ''}`} target="_blank" rel="noreferrer">
                                            {/* <a className='btn-primary flex items-center px-4 py-1.5 ml-5 lg:md-0' href={`https://wa.me/${cs.whatsapp_number}`} target="_blank"> */}
                                            <i className='ri-whatsapp-line text-xl md:mr-1.5'></i>
                                            <p className='hidden md:block'>Contact</p>
                                        </a>
                                    </div>
                                )
                                :
                                <div className='flex justify-center items-center h-32'>
                                    No customer service exist
                                </div>
                        }
                    </div>

                    <div className="flex items-center mt-7 pt-4 space-x-4 rounded-b border-t border-gray-300 dark:border-gray-600 ">
                        <button type='reset' className="btn-primary" onClick={onClose}>Close</button>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ContactCSModal;