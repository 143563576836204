import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import Auth from './layouts/Auth';
import Dashboard from './layouts/Dashboard';
import NotFound from './pages/NotFound';
import Homepage from './pages/Homepage';
import { ToastContainer } from 'react-toastify';
import ResetPassword from './pages/ResetPassword';
import { ThemeProvider } from './contexts/ThemeContext';

type Props = {};

const App: React.FC<Props> = () => {
	const token = localStorage.getItem('authToken')

	return (
		<>
			<div className='font-poppins text-sm'>
				<ThemeProvider /* initialTheme='light' */>
					<Router>
						<Routes>
							<Route path='/root/*' element={<Homepage />} />
							<Route path='/auth/*' element={<Auth />} />
							<Route path='/reset-password' element={<ResetPassword />} />
							<Route path='/admin/*' element={<Dashboard />} />
							<Route path='*' element={<NotFound className='text-white min-h-screen' />} />
							{token ?
								<Route path='/' element={<Navigate replace to='/root' />} />
								:
								<Route path='/' element={<Navigate replace to='/auth' />} />
							}
						</Routes>
					</Router>
				</ThemeProvider>
			</div>
			<div className='text-sm'>
				<ToastContainer
					position='bottom-left'
					theme='dark'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
			</div>
		</>
	)
}

export default App;