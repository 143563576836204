import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import DocumentAPI, { IFolderAndFile } from '../../api/document-api';
import Helper from '../../utils/Helper';
import ErrorField from '../ErrorField';
import Loader from '../Loader';

type Props = {
    onSubmit: (isNewFolder: boolean) => void,
    onClose?: (e: React.MouseEvent<HTMLElement>) => void
};

const NewFolderModal: React.FC<Props> = ({ onSubmit, onClose }) => {
    const [loader, showLoader] = useState(false)
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<any>({ criteriaMode: "all" });

    const onSubmitForm: SubmitHandler<IFolderAndFile> = async ({ name }) => {
        showLoader(true)

        const res = await DocumentAPI.createFolder('uploads/'+ name?.split(' ').join('_'))
        console.log("Action :", res)

        if (res.status === 200) {
            if (res.data.success) {
                toast.success(res.data.message)
                onSubmit(true)
            }
        } else {
            Helper.bulkResponseError(res)
            showLoader(false)
        }
    }

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside opacity-70" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-6/12 2xl:w-4/12 bg-slate-800 text-white rounded-lg shadow z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-gray-600 mb-5">
                        <div className="md:text-xl font-medium flex item-center space-x-2 lg:space-x-3">
                            <i className="bi bi-folder-plus"></i>
                            <h3>Create New Folder</h3>
                        </div>
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className='space-y-5'>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="field_name" className="label-form">Folder Name</label>
                                    <input type="text" id="field_name" className="input-form"
                                        {...register("name", { required: "Directory name is required." })} />
                                    <ErrorField errors={errors} name='name' />
                                </div>
                            </div>

                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-7 pt-4 space-x-4 rounded-b border-t border-gray-600 -mx-10 px-10">
                            <button type='submit' className="btn-primary px-4">Create Folder</button>
                            <button type='reset' className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>

                    </form>

                </div>
            </div>
            {loader && <Loader title='Creating new folder...' />}
        </>
    );
}

export default NewFolderModal;