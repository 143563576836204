import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IFolderAndFile } from '../../api/document-api';
import ErrorField from '../ErrorField';

type Props = {
    data?: any,
    onSubmit: (data: IFolderAndFile) => void,
    onClose?: (e: React.MouseEvent<HTMLElement>) => void,
    type: 'file' | 'folder'
};

const RenameModal: React.FC<Props> = ({ data, onSubmit, onClose, type }) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            if(obj[key].toString().split('.').length > 1){
                return setValue(key, obj[key].split('.')[0], { shouldValidate: true })
            }
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])


    const onSubmitForm: SubmitHandler<IFolderAndFile> = ({ name }) => {

        const payload = {
            name
        }

        onSubmit(payload)
    }

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-6/12 2xl:w-5/12 text-slate-700 bg-soft dark:bg-slate-800 dark:text-white rounded-lg shadow z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-slate-400 dark:border-gray-600 mb-5">
                        <div className="md:text-xl font-medium flex item-center space-x-2 lg:space-x-3">
                            <i className="bi bi-pencil-square"></i>
                            <h3>Rename {type==='file'? 'File': 'Folder'}</h3>
                        </div>
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className='space-y-5'>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="field_name" className="label-form text-slate-700 dark:text-slate-300">{type==='file'? 'Filename': 'Folder Name'}</label>
                                    <input type="text" id="field_name" className="input-form focus:outline-none bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-500 dark:placeholder-slate-400 text-slate-600 dark:text-white"
                                        {...register("name", { required: "File/Dir name is required." })} />
                                    <ErrorField errors={errors} name='name' />
                                </div>
                            </div>

                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-7 pt-4 space-x-4 rounded-b border-t border-slate-400 dark:border-gray-600 -mx-10 px-10">
                            <button type='submit' className="btn-primary px-7">Rename</button>
                            <button type='reset' className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default RenameModal;