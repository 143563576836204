import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom';
import ErrorField from '../../components/ErrorField';
import appLogo from '../../assets/img/logo-sip.png'
import { SubmitHandler, useForm } from 'react-hook-form';
import { getConfig, getLoginInfo, loginAPI } from '../../api/common-api';
import Loader from '../../components/Loader';
import { UserPayload } from '../../api/user-api';
import Helper from '../../utils/Helper';
import { ThemeContext } from '../../contexts/ThemeContext';

type Props = {};

const Login: React.FC<Props> = () => {
    const [loader, showLoader] = useState(false)
    const [title, setTitleLoader] = useState('Please wait...')

    const navigate = useNavigate()
    const { setUserStorage } = useContext(ThemeContext);

    const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<UserPayload>({ criteriaMode: "all" });

    useEffect(() => {
        localStorage.clear()
    }, [])

	const onSubmitForm: SubmitHandler<UserPayload> = async ({username, password}) => {
        showLoader(true)
        const payload = {
            username, password,
            device_name: 'web'
        }
        
        const res = await loginAPI(payload)
        console.log("Login :", res)

        if(res.status === 200){
            const { token } = res.data.data
            setTitleLoader("Loading configuration...")

            const { data } = await getLoginInfo(token)
            const resConfig = await getConfig('', token)
            if(resConfig.status === 200){
                if(resConfig.data.success){
                    localStorage.setItem('config', JSON.stringify(resConfig.data.data))
                }
            }
            setUserStorage(data)
            localStorage.setItem('authToken', token)
            navigate('/root', {replace: true})
        }else{
            Helper.bulkResponseError(res)
            showLoader(false)
        }
	}


    return (
        <>
            <div className="h-full flex flex-col justify-center items-center space-y-8">

                <LazyLoadImage effect='blur' src={appLogo} className='w-20 h-20 mx-auto' />

                <div className='text-center'>
                    <h1 className="text-2xl md:text-3xl font-extrabold">Sign in to your account</h1>
                    <p className="mt-5">Electronic System Administration Pajak</p>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)} className="space-y-7 px-3 md:px-0 w-11/12 md:w-7/12">
                    <div>
                        <label className="font-semibold" htmlFor="username">Username</label>
                        <input id="username" type="text" autoComplete="username" className="text-input"
                            {...register("username", {
                                required: "username is required."
                            })} />
                        <ErrorField errors={errors} name='username' />
                    </div>
                    <div>
                        <label className="font-semibold" htmlFor="password">Password</label>
                        <input id="password" type="password" autoComplete="current-password" className="text-input"
                            {...register("password", {
                                required: "Password is required.",
                                minLength: { value: 5, message: "Password must exceed 4 characters." }
                            })} />
                        <ErrorField errors={errors} name='password' />
                    </div>

                    <div className="flex items-center justify-between text-sm">
                        <div className="flex items-center">
                            <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 text-orange-400 focus:ring-orange-400 border-gray-300 rounded" />
                            <label htmlFor="remember-me" className="ml-2 block font-medium cursor-pointer">Remember me</label>
                        </div>
                        <Link to='/auth/forgot-password' className='flex items-center text-orange-400 hover:text-orange-500'>
                            <i className="ri-lock-fill text-base"></i>
                            <p className="font-medium ml-1">Forgot your password?</p>
                        </Link>
                    </div>

                    <button type='submit' className="btn-submit" >
                        Sign In
                    </button>

                </form>

                <div className='font-medium text-sm flex items-center justify-center'>
                    <p>Don't have an account?</p>
                    <Link to='/auth/sign-up' className='text-orange-400 hover:text-orange-500 ml-1'>Sign up</Link>
                </div>

                <div className='font-medium text-sm flex items-center justify-center text-dark'>
                    © Electronic System Administration Pajak v1.0
                </div>

            </div>
            
            {loader && <Loader title={title} />}
        </>
    );
}

export default Login;