import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { UserPayload } from '../../api/user-api';
import ErrorField from '../ErrorField';

type Props = {
    data?: any,
    onSubmit: (data: UserPayload) => void,
    onClose: (e: React.MouseEvent<HTMLElement>) => void
};

const ChangePasswordForm: React.FC<Props> = ({ data, onSubmit, onClose }) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<any>({ criteriaMode: "all" });


    const onSubmitForm: SubmitHandler<UserPayload> = ({ password, password_confirmation }) => {
        if (password !== password_confirmation) {
            toast.warning("Password not match")
            return
        }

        const payload = {
            password, password_confirmation
        }

        onSubmit(payload)
    }

    return (
        <>
            <div className="modal-form">
                <div className="modal-form-outside" onClick={onClose} />
                {/* <!-- Modal content --> */}
                <div className="w-11/12 md:w-8/12 2xl:w-6/12 text-white rounded-lg shadow bg-slate-800 z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                    <div className="flex justify-between items-start p-5 rounded-t border-b border-gray-600 mb-10">
                        <div className="md:text-xl font-medium text-white flex item-center space-x-2">
                            <i className="ri-lock-password-fill"></i>
                            <h3>{data.username}'s Password Change</h3>
                        </div>
                        <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                            onClick={onClose}
                        >
                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                        <div className='space-y-5'>
                            <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                                <div className="w-full">
                                    <label htmlFor="password" className="label-form">Passsword</label>
                                    <input type="password" id="password" placeholder="••••••••" className="input-form"
                                        {...register("password", {
                                            required: "Password is required.",
                                            minLength: { value: 5, message: "Password must exceed 4 characters." }
                                        })}
                                    />
                                    <ErrorField errors={errors} name='password' />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="password_confirmation" className="label-form">Confirm Password</label>
                                    <input type="password" id="password_confirmation" className="input-form" placeholder="••••••••"
                                        {...register("password_confirmation", { required: "Confirm password is required." })} />
                                    <ErrorField errors={errors} name='password_confirmation' />
                                </div>
                            </div>

                        </div>

                        {/* <!-- Modal footer --> */}
                        <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-gray-600 -mx-10 px-10">
                            <button type='submit' className="btn-primary px-7">Save</button>
                            <button type='reset' className="btn-secondary" onClick={onClose}>Cancel</button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    );
}

export default ChangePasswordForm;