import React, { MouseEvent, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomerServicePayload } from '../../api/cs-api';
import Helper from '../../utils/Helper';
import ErrorField from '../ErrorField';


const LIST_GENDER = [
    { key: 'Male', value: 'L' },
    { key: 'Female', value: 'P' },
]

const LIST_STATUS = [
    { key: 'Active', value: 1 },
    { key: 'Inactive', value: 0 }
]


type Props = {
    data?: any,
    onSubmit: (data: CustomerServicePayload) => void,
    onClose: (e: MouseEvent<HTMLElement>) => void
};

const CustomerServiceForm: React.FC<Props> = ({ data, onSubmit, onClose }) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm<any>({ criteriaMode: "all" });

    const setdefaultValue = useCallback((obj: any) => {
        Object.keys(obj).map(key => {
            console.log(key, obj[key])
            // if(key === 'status_active'){
                // return setValue(key, obj[key].toString(), { shouldValidate: true })
            // }else{
                return setValue(key, obj[key], { shouldValidate: true })
            // }
        })
    }, [setValue])

    useEffect(() => {
        if (data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])


    const onSubmitForm: SubmitHandler<CustomerServicePayload> = ({ name, whatsapp_number, gender, status_active }) => {

        const payload = {
            name, whatsapp_number: Helper.indonesiaPhone(whatsapp_number!), gender, status_active
        } as CustomerServicePayload


        onSubmit(payload)
    }

    return <>
        <div className="modal-form">
            <div className="modal-form-outside" onClick={onClose} />
            {/* <!-- Modal content --> */}
            <div className="w-11/12 md:w-8/12 2xl:w-6/12 text-white rounded-lg shadow bg-slate-800 z-50 overflow-y-auto overflow-x-hidden" style={{ maxHeight: '90vh' }}>

                <div className="flex justify-between items-start p-5 rounded-t border-b border-gray-600 mb-10">
                    <div className="md:text-xl font-medium text-white flex item-center space-x-2">
                        {data ? <i className="ri-user-settings-fill"></i> : <i className="ri-user-add-fill"></i>}
                        <h3>{data ? 'Update Customer Service' : 'Create Customer Service'}</h3>
                    </div>
                    <button type="button" className="text-gray-400 bg-transparent rounded-lg text-sm ml-auto inline-flex items-center hover:bg-gray-600 hover:text-white"
                        onClick={onClose}
                    >
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div>

                <form onSubmit={handleSubmit(onSubmitForm)} className="px-6 pb-4 lg:px-8 sm:pb-6 xl:pb-8">
                    <div className='space-y-5'>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="name" className="label-form">Fullname</label>
                                <input type="text" id="name" className="input-form" placeholder="Full Name"
                                    {...register('name', {
                                        required: 'Name is required',
                                        maxLength: { value: 50, message: 'Name is too long' }
                                    })}
                                />
                                <ErrorField errors={errors} name='name' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="whatsapp_number" className="label-form">Whatsapp Number</label>
                                <input type="tel" id="whatsapp_number" className="input-form" placeholder="62812345xxxx"
                                    {...register("whatsapp_number", {
                                        required: 'Whatsapp number is required',
                                        minLength: { value: 9, message: 'Whatsapp number is too short' },
                                        maxLength: { value: 14, message: 'Whatsapp number is too long' }
                                    })} />
                                <ErrorField errors={errors} name='whatsapp_number' />
                            </div>
                        </div>
                        <div className="md:flex md:space-x-10 space-y-5 md:space-y-0">
                            <div className="w-full">
                                <label htmlFor="gender" className="label-form">Gender</label>
                                <select id="gender" className="input-form" {...register('gender', { required: 'Gender is required' })}>
                                    <option value=''>- Select Gender -</option>
                                    {LIST_GENDER.map((item, key) =>
                                        <option key={key} value={item.value}>{item.key}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='gender' />
                            </div>
                            <div className="w-full">
                                <label htmlFor="status_active" className="label-form">Status Active</label>
                                <select id="status_active" className="input-form" {...register('status_active', { required: 'Status active is required' })}>
                                    <option value=''>- Select Status Active -</option>
                                    {LIST_STATUS.map((item, key) =>
                                        <option key={key} value={item.value.toString()}>{item.key}</option>
                                    )}
                                </select>
                                <ErrorField errors={errors} name='status_active' />
                            </div>
                        </div>

                    </div>

                    {/* <!-- Modal footer --> */}
                    <div className="flex items-center mt-11 pt-4 space-x-4 rounded-b border-t border-gray-600 -mx-10 px-10">
                        <button type='submit' className="btn-primary px-7">Save</button>
                        <button type='reset' className="btn-secondary" onClick={onClose}>Cancel</button>
                    </div>

                </form>

            </div>
        </div>
    </>
}

export default CustomerServiceForm;