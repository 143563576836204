import { atom } from "recoil";
import { IConfig } from "../hooks/useConfig";


export const actionTypeState = atom({
    key: 'actionType',
    default: 'cp'
})

export const actionDataState = atom({
    key: 'actionData',
}) 

export const actionMultiDataState = atom({
    key: 'actionMultiDataState',
}) 

export const configurationState = atom({
    key: 'configurationState',
    default: [] as IConfig[]
})


export const showInformationState = atom({
    key: 'showInformationState',
    default: true
})